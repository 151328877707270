import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { Validators } from "@angular/forms";
import { FormlyDateSuggestion, FormlySuggestionsSynchronizationHelper } from "../../../helpers/formly-suggestions-synchronization-helper";

@Component({
  selector: "formly-date",
  templateUrl: "./formly-date.component.html",
  styleUrls: ["./formly-date.component.css"]
})
export class FormlyDateComponent extends FieldType implements OnInit {
  public submitted: boolean;
  public suggestion: Date | null = null;

  constructor(public formlyHelper: FormlyHelper, public formlySuggestionsSynchronizationHelper: FormlySuggestionsSynchronizationHelper) {
    super();
  }

  public ngOnInit() {
    this.formlySuggestionsSynchronizationHelper.getDateSuggestionObservable$().subscribe((x: FormlyDateSuggestion) => {
      if (x.id == this.key) {
        this.suggestion = x.suggestion;
      }
    })

    this.formlyHelper.getGeneralFormSubmission$()
      .subscribe(res => this.submitted = true);
    if (this.field.templateOptions.required) {
      this.formControl.setValidators(Validators.required);
    }
  }

  public applySuggestion() {
    this.form.controls[this.field.key as any].patchValue(this.suggestion)
  }

  public get getCurrentControlValue() {
    return this.form.controls[this.field.key as any].value
  }
}
