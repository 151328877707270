<div class="modal-header">
  <h4 class="modal-title"
      id="modal-basic-title">{{ 'FINANCE.INCOMING_INVOICES_DETAILS.UPLOAD_UBL' | translate }}</h4>
</div>
<div class="modal-body">
  <div class="row">
    <p class="col-12"
       translate>FINANCE.INCOMING_INVOICES_DETAILS.SELECT_UBL_FILE</p>
    <p class="col-12"
       translate>FINANCE.INCOMING_INVOICES_DETAILS.NON_UBL_FILE_UNDER_DEVELOPMENT</p>
    <div class="col-12">
      <div class="upload-file-container"
           (click)="uploader.click()"
           dragDrop
           (onFileDropped)="addFileToUploader($event)">
        <input hidden
               type="file"
               class="truncate"
               multiple
               accept="text/xml,application/pdf,image/png,image/jpeg,image/jpg,image/tiff"
               #uploader
               (change)="addFileToUploader($event.target.files)">
      </div>

      <div class="files-list row"
           *ngFor="let file of filesToUpload">
        <p class="mt-2 mb-2 truncate col-10"> {{ file.name }} </p>
        <button class="delete-file col-2"
                (click)="deleteAttachment(file.name)">
          <i class="la la-trash"></i>
        </button>
      </div>

      <input hidden
             type="file"
             accept=".xml, .pdf, .jpg, .jpeg, .tif, .tiff, .png"
             #uploader />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          [disabled]="!hasFileName"
          (click)="uploadFile()"
          class="btn btn-success m-btn">
    <i class="la la-upload"></i>
    <span>{{ 'ACTIONS.UPLOAD' | translate }}</span>
  </button>
  <button type="button"
          (click)="modal.close()"
          class="btn btn-secondary m-btn">
    <i class="la la-times"></i>
    <span>{{ 'ACTIONS.CANCEL' | translate }}</span>
  </button>
</div>