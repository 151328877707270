<div class="modal-settings">
  <!--entities-->
  <h4 class="ml-2 mb-3">{{data.entitesListLabel | translate}}</h4>
  <div *ngFor="let item of data.entities"
       class="d-flex align-items-center mb-3 ml-2">
    <div class="border rounded flex-grow-1 mr-3 p-2">
      <p class="mb-1 wrap-long-text "><b>{{item.label}}</b></p>
      <hr class="m-0 p-0 mt-1">
      <div *ngFor="let details of item.entityDetails">
        <div class="m-0 wrap-long-text"
             *ngIf="details.value">
          {{details.key | translate}}: {{ details.value }}
        </div>
      </div>
      <p class="mb-1 wrap-long-text "><b>{{item.description}}</b></p>
    </div>
    <div>

      <button type="button"
              class="btn btn-primary m-btn mr-2"
              (click)="navigate(item.value)">
        <i class="la la-edit pr-2"></i>
        <span>{{ 'ACTIONS.NAVIGATE' | translate }} </span>
      </button>
    </div>
  </div>

  <ng-container>
    <div mat-dialog-actions
         align="end">
      <button (click)="cancel()"
              class="m-btn btn btn-secondary">
        <i class="la la-remove"></i>
        <span>{{ "ACTIONS.CLOSE" | translate }}</span>
      </button>
    </div>
  </ng-container>
</div>