<div>
  <div class="input-group"
       [class.is-invalid]="formErrors">
    <input class="form-control"
           ngbDatepicker
           (input)="onInput($event)"
           [placement]="placement"
           #datePicker="ngbDatepicker"
           [placeholder]="'PLACEHOLDERS.DATE_FORMAT' | translate"
           [tabIndex]="tabIndex"
           [value]="selectedDate"
           (click)="datePicker.close()"
           (blur)="onTextChanged($event)"
           (change)="onTextChanged($event)"
           (dateSelect)="onDateSelected($event)"
           [startDate]="startDate"
           [minDate]="minDate"
           [maxDate]="maxDate"
           [readonly]="isDisabled"
           [footerTemplate]="setTodayButton"
           [dateCharactersOnly]="onlyAllowDateCharacters"
           [(ngModel)]="ngbDate" />
    <div class="input-group-append">
      <button class="btn"
              type="button"
              [disabled]="isDisabled"
              (click)="datePicker.open()">
        <i class="la la-calendar"></i>
      </button>
    </div>
  </div>
  <div class="invalid-text"
       *ngIf="formErrors && errorConfig && formErrors[errorConfig.errorKey]">{{errorConfig.errorMessage}}</div>
</div>

<ng-template #setTodayButton>
  <button class="btn btn-primary m-btn w-100"
          type="button"
          (click)="todaySelected(); datePicker.toggle()"
          [disabled]="isDisabled || minDateMoreThanToday() || maxDateLessThanToday()">{{'PLACEHOLDERS.TODAY' | translate}}</button>
</ng-template>
