<div *ngIf="!isAirport; else airport">
  <ng-select *ngIf="!isAirport; else airport"
             #ngSelect
             [ngbTooltip]="ngSelect.element?.firstChild?.firstChild?.childNodes[1]?.innerText"
             (ngModelChange)="cdRef.detectChanges()"
             [disableTooltip]="ngSelect.isOpen || formControl?.value == null"
             [openDelay]="300"
             placement="bottom"
             [items]="mappedOptions"
             bindValue="id"
             [id]="id"
             [loading]="loading"
             bindLabel="description"
             [searchFn]="searchObjectHelper.searchStringInObject"
             [formControl]="formControl">
  </ng-select>
</div>

<ng-template #airport>
  <ng-select [items]="mappedOptions"
             #ngSelect1
             [ngbTooltip]="ngSelect1.element?.firstChild?.firstChild?.childNodes[1]?.innerText"
             (ngModelChange)="cdRef.detectChanges()"
             [disableTooltip]="ngSelect1.isOpen || formControl?.value == null"
             [openDelay]="300"
             placement="bottom"
             bindValue="id"
             [id]="id"
             [loading]="loading"
             bindLabel="name"
             [searchFn]="searchObjectHelper.searchStringInObject"
             [formControl]="formControl">

    <ng-template ng-label-tmp
                 let-item="item">
      <span class="ng-value-label">{{item.iataCode}} - {{item.name}} ({{item.countryName}})</span>
    </ng-template>

    <ng-template ng-option-tmp
                 let-item="item">
      <strong>{{ item.name }}</strong><br />
      <span>{{ item.countryName }}</span><br />
      <span>{{ item.iataCode }}</span>
    </ng-template>

  </ng-select>

</ng-template>
