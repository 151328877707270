<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{id | translate}}</label>
  <div class="col-9">
    <div [formGroup]="form"
         class="date-input">
      <date-time-input [minDate]="getMinDate()"
                       [class.is-invalid]="submitted && formControl.errors"
                       [formControlName]="field.key"></date-time-input>
    </div>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.lessThanDeparture"
           translate
           [translateParams]="{ name: field.id | translate, before: 'OPERATIONAL.EDIT.ACTUALTIMEOFDEPARTURE' | translate }">
        VALIDATION.NOT_BEFORE_DATE
      </div>
    </div>
  </div>
</div>
