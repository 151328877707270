<input type="text"
       class="form-control m-input exchange-fixed-width"
       [name]="name"
       [placeholder]="placeholder"
       [readonly]="readonly"
       [disabled]="disabled"
       [ngModel]="inputValue"
       (keydown.arrowup)="valueUpClick()"
       (keydown.arrowdown)="valueDownClick()"
       (keypress)="keyPress($event)"
       (paste)="paste($event)"
       (input)="input($event)">
<div class="number-input-buttons"
     *ngIf="!readonly && !disabled && !controlDisabled">
  <div class="number-input-button"
       (click)="valueUpClick()">
    <div class="number-input-arrow number-input-arrow-up"></div>
  </div>
  <div class="number-input-button"
       (click)="valueDownClick()">
    <div class="number-input-arrow number-input-arrow-down"></div>
  </div>
</div>
