export const incoming_invoices_details_locale = {
  FILE_NAME_WAS_CHANGED: "The file name has been changed to avoid overwriting an existing file with the same name.",
  LINE_EXTENSION_AMOUNT: "Line extension amount",
  TAX_INCLUSIVE_AMOUNT: "Amount incl. VAT",
  VAT: "VAT",
  TAX_EXCLUSIVE_AMOUNT: "Amount excl. VAT",
  PAYABLE_AMOUNT: "Payable amount",
  TAXCODE: "Tax code",
  TAXSYSTEM: "Tax system",
  COMMENT: "Comment",
  FILE_CURRENCY: "Invoice currency",
  PAID_DATE: "Paid on",
  INVOICE_TYPE: "Invoice type",
  GENERAL: {
    FILE_CANCELLED: "The operational file {{fileNumber}} is canceled and cannot be used.",
    OPERATIONAL_FILE_DOES_NOT_EXIST: "This operational-file does not exist.",
    OPERATIONAL_FILE_ALREADY_LOADED: "You already selected this operational-file.",
    OPERATIONAL_FILE_MISSING_DEPARTURE_LOCATION: "No port of loading or place of receipt has been provided on this operational file. Please do so first.",
    OPERATIONAL_FILE_MISSING_DESTINATION_LOCATION: "No port of discharge or place of destination has been provided on this operational file. Please do so first.",
  },
  ACTIONS: {
    FINAL: "finalize",
    PREVIEW: "preview",
    MARK_AS_PAID: "mark as paid",
    MARK_AS_UNPAID: "mark as unpaid",
  },
  SELECTED_OPERATIONAL_FILE: "Selected operational-file",
  NO_BOOKABLE_COSTS_FOUND_IN_OP_FILE: "There are no costs to validate in the selected operational-file.",
  NO_LINES_IN_INVOICE: "There are no lines in this incoming invoice.",
  PARTY_NAME: "Name",
  DESCRIPTION: "Description",
  QUANTITY: "Quantity",
  PRICE_EXCL_VAT: "Price excl. VAT",
  TOTAL_EXCL_VAT: "Total excl. VAT",
  TOTAL: "Total",
  BOOK: "Book",
  UNBOOK: "Unbook",
  PAID: "Paid",
  FINALIZE_DONE: "The incoming invoice has been finalized.",
  CONFIRM_FINALIZE: "Are you sure you want to finalize this incoming invoice?",
  FINAL: "Final",
  NO_OPERATIONAL_FILES: "No operational files selected. Please add at least one file to validate costs.",
  FILE_NUMBER: "Operational file number",
  UPLOAD_DOCUMENT: "Upload document",
  SELECTED_DOCUMENT: "Selected document",
  UPLOAD_PDF_OR_IMAGE: "Only the following file extensions are allowed: .pdf, .jpg, .jpeg, .tiff and .png.",
  ONLY_ONE_FILE_AVAILABLE_FOR_UPLOAD: "Only one file can be uploaded to an invoice.",
  ADD_OUT_OF_BUDGET_COST: "Add out of budget cost",
  OUT_OF_BUDGET_COST: "Out of budget cost",
  VALIDATE_INCOMING_INVOICE: "Would you like to validate this incoming invoice?",
  VALIDATE_OPERATIONAL_FILE_COST: "Would you like to validate this operational file cost?",
  UNVALIDATE_OPERATIONAL_FILE_COST: "Would you like to unvalidate this operational file cost?",
  BOOK_INCOMING_INVOICE: "Would you like to book this incoming invoice?",
  UNBOOK_INCOMING_INVOICE: "Would you like to unbook this incoming invoice?",
  NO_DOCUMENT_UPLOADED_WARNING_MESSAGE: "No document was uploaded was this invoice.",
  PROTEST_INCOMING_INVOICE: "Would you like to protest this incoming invoice?",
  UNPROTEST_INCOMING_INVOICE: "Would you like to unprotest this incoming invoice?",
  DELETE_INCOMING_INVOICE: "Are you sure you want to delete this incoming invoice?",
  DELETE_INCOMING_INVOICE_TITLE: "Delete incoming invoice",
  BOOK_OPERATIONAL_FILE_COST: "Would you like to book this operational file cost?",
  UNBOOK_OPERATIONAL_FILE_COST: "Would you like to unbook this operational file cost?",
  SUMMARY: {
    SUMMARY: "Summary",
    VALIDATED_EXCL_AMOUNT: "Validated excl. amount",
    VALIDATED_VAT: "Validated VAT",
    VALIDATED_TOTAL_AMOUNT: "Validated total amount",
    INCOMING_EXCLUSIVE_AMOUNT: "Incoming excl. amount",
    INCOMING_VAT: "Incoming VAT",
    INCOMING_AMOUNT: "Total incoming amount",
    DIFFERENCE_EXCLUSIVE_VAT: "Difference in amount",
    DIFFERENCE_VAT: "Difference in VAT",
    TOTAL_DIFFERENCE: "Total difference"
  },
  LINES_VALIDATION_DIALOG: {
    TITLE: "Before we can add this invoice, we need some financial information (you can change this later)"
  },
  SPLIT_COST_DIALOG_TITLE: "Split cost",
  SPLIT_BY: "Split by",
  TO_BE_MOVED_TO_THIS_LINE: "To be moved to this line",
  REMAINING: "Remaining",
  SUPPLIER: "Supplier",
  INVOICE_NUMBER_NOT_SPECIDIED: "Invoice number is not specified",
  // TODO: find better way of doing this, this translation string is used for string replacement when creating an incoming invoice from budget --> costs --> create incoming invoice.
  // This could cause issues when supporting multiple languages.
  ENTER_INVOICE_NUMBER: "Please enter invoice number",
  MESSAGES: {
    NO_OPERATIONAL_FILE_SELECTED: "Please select an operational file first.",
    PROVIDED_AMOUNT_INCL_VAT_NOT_EQUAL_TO_CALCULATED: "The amount incl. VAT does not equal the amount excl. VAT + VAT.",
    ALREADY_EXISTS: "Based on document you have provided {{documentName}}, system recognized that an incoming invoice with the same invoice number, supplier and issue date already exists.",
    ADD_BUDGET_COST_TO_CLOSED_OF: "This Operational file is Closed. Do you want to continue adding an out of budget cost?",
  },
  NEW_INVOICE: "New incoming invoice",
  INVOICE_NUMBER: "Invoice number",
  DRAFT_NUMBER: "Draft number",
  EXCL_VAT_MUST_BE_LTE_INCL_VAT: "The amount excl. VAT should be less than or equal to the amount incl. VAT.",
  INCL_VAT_MUST_BE_GREATER_EXCL_VAT_AND_VAT: "The amount incl. VAT should be greater than or equal to the amount excl. VAT + amount VAT.",
  UPLOAD_UBL: "Create incoming invoice from UBL",
  SELECT_UBL_FILE: "Please select a file (UBL .xml, pdf*, .jpg*, .tiff*, .png*).",
  NON_UBL_FILE_UNDER_DEVELOPMENT: "*) Support for non-UBL formats is under development and may still have issues. Double-check any data the system identifies.",
  EXCL_VAT_MAX_VALUE: "The amount excl. VAT should be less than 10 000 000 000.",
  INCL_VAT_MAX_VALUE: "The amount incl. VAT should be less than 10 000 000 000.",
  LINES_REQUIRED_DATA_POPOVER: {
    TITLE: "Attention",
    TEXT: "This field is required for invoice lines."
  },
  MODALS: {
    SET_PAID_DATE_TITLE: "Payment date",
    SET_PAID_DATE: "Choose a payment date"
  },
  PROTESTED_INVOICE_WITH_TIMESTAMP: "This invoice was protested on '{{protestTimestamp}}'",
  PROTESTED_INVOICE_WITH_REASON: "for reason '{{protestReason}}'",
  PROTESTED_INVOICE_WITH_RESOLVED: ", but this has subsequently been resolved",
  PAYMENT_REFERENCE: "Payment reference",
};
