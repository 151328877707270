/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompanyProspectDTO } from '../model/companyProspectDTO';
import { NewCompanyProspectDTO } from '../model/newCompanyProspectDTO';
import { PromoteProspectCompanyDTO } from '../model/promoteProspectCompanyDTO';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CompanyProspectService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a company prospect
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProspectCompany(body?: NewCompanyProspectDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public addProspectCompany(body?: NewCompanyProspectDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public addProspectCompany(body?: NewCompanyProspectDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public addProspectCompany(body?: NewCompanyProspectDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/masterdata/companies/prospects`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete Prospect company
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProspectCompany(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteProspectCompany(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteProspectCompany(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteProspectCompany(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProspectCompany.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/masterdata/companies/prospects/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Promote company to customer
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public promoteProspectCompany(id: number, body?: PromoteProspectCompanyDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public promoteProspectCompany(id: number, body?: PromoteProspectCompanyDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public promoteProspectCompany(id: number, body?: PromoteProspectCompanyDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public promoteProspectCompany(id: number, body?: PromoteProspectCompanyDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling promoteProspectCompany.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/masterdata/companies/prospects/${encodeURIComponent(String(id))}/promote/customer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update a company prospect
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProspectCompany(id: number, body?: CompanyProspectDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public updateProspectCompany(id: number, body?: CompanyProspectDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public updateProspectCompany(id: number, body?: CompanyProspectDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public updateProspectCompany(id: number, body?: CompanyProspectDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProspectCompany.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<number>(`${this.basePath}/api/masterdata/companies/prospects/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
