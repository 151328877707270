<a *ngIf="containsRelease"
   href="javascript:;"
   class="m-nav__link m-dropdown__toggle"
   (click)="openReleasePreviewDialog()">
  <span class="m-nav__link-icon">
    <span class="m-nav__link-icon-wrapper">
      <i class="la la-info-circle"></i>
    </span>
  </span>
</a>
