export const company_list_locale = {
  FILTER: {
    COMPANYNAME: "Company name",
    COMPANYTYPE: "Company type",
    LEGACYACCOUNTINGNUMBER: "Account Nr.",
    STATEPROVINCEREGION: "State/Province",
    ADDRESSLINE1: "Address line 1",
    ADDRESSLINE2: "Address line 2",
    ZIPCODE: "Zip code",
    COUNTRYNAME: "Country name",
    VATNUMBER: "VAT Nr.",
    PLACE: "Place",
    MESSAGETYPE: "Message type",
    STATUS: "Status",
  }
};
