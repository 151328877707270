<div class="input-group d-flex flex-row flex-wrap exchange-rate"
     [class.ng-invalid-input-group]="invalid">
  <number-input step="0.000001"
                min="0"
                precision="10:8"
                [ngModel]="value"
                (ngModelChange)="updateChanges($event)"
                [positiveonly]="true"
                [disabled]="disabled"></number-input>
  <div class="input-group-append exchange-fixed-width">
    <span class="input-group-text w-100">
      1,00 {{ 'USD' | translate }} <i class="la la-arrow-right ml-1 mr-1"></i> {{calculatedCourseAmount | number : this.formatService.getExchangeRateFormat() }}
      <div class="ml-1 mr-1">
        <span> {{ 'EUR' | translate  }}</span>
      </div>
    </span>

    <!-- RAWU: leave this in comment
                <span class="input-group-text">
                1,00 {{ defaultCurrency ? defaultCurrency.description : "usd" }} <i class="la la-arrow-right ml-1 mr-1"></i> {{calculatedCourseAmount | number : '1.2-2'}}
                <div class="ml-1 mr-1">
                    <span> {{ this.operCurrencyDesc ? this.operCurrencyDesc : "eur" }}</span>
                </div>
            </span> -->
  </div>
</div>
