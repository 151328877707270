<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{id | translate}}</label>
  <div class="col-9"
       [class.ng-invalid-input-group]=" submitted && formControl.errors">
    <div [formGroup]="form"
         class="date-input">
      <date-input [formControlName]="field.key"
                  [class.is-invalid]="submitted && formControl.errors">
      </date-input>
      <div *ngIf="suggestion && suggestion!=getCurrentControlValue"
           class="alert alert-warning pl-3 mt-3 fade show d-flex justify-content-between align-items-center p-1">
        <div>
          {{"SUGGESTED" | translate}} {{id | translate}}: {{suggestion | date:'dd-MM-yyyy'}}
        </div>
        <div>
          <button (click)="applySuggestion()"
                  class="btn btn-primary m-btn">{{"ACTIONS.APPLY" | translate}}</button>
        </div>
      </div>

    </div>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: field.id | translate }">
        VALIDATION.REQUIRED
      </div>
    </div>
  </div>
</div>