import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthenticationModule } from "./core/auth/authentication.module";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GlobalHTTPErrorInterceptorService } from "./core/interceptors/global-http-error-interceptor.service";
import { registerLocaleData, Location } from "@angular/common";
import localeEnBe from "@angular/common/locales/en-BE";
import { authConfig } from "./core/auth/OAuth2.config";
import { LoaderInterceptor } from "./core/services/loading-overlay/loading-overlay.interceptor";
import { AuthRedirectInterceptor } from "./core/auth/interceptors/auth.interceptor";
import { environment } from "../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { locale as enLang } from "./config/i18n/en";
import { TranslationService } from "./core/services/translation.service";
import { ApiModule as FordeskApiModule, BASE_PATH as FORDESK_BASE_PATH } from "./core/services/swagger-gen/fordesk";
import { ApiModule as IdentityApiModule, BASE_PATH as IDENTITY_BASE_PATH } from "./core/services/swagger-gen/identity";
import { PagesModule } from "./content/pages/pages.module";
import { SharedModule } from "./content/shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { MatIconRegistry } from "@angular/material/icon";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { OAuthService, OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { ClickOutsideModule } from 'ng-click-outside';

export function initApplication(
  oauthService: OAuthService,
  translationService: TranslationService,
  location: Location
) {
  return () => {
    if (location.path().startsWith('/config/integrations/exact-online/auth')) {
      // Load translations but skip OAuth-related initialization
      return translationService.loadTranslations(enLang).toPromise();
    } else {
      return translationService.loadTranslations(enLang)
        .toPromise()
        .then(() => {
          oauthService.configure(authConfig);
          oauthService.tokenValidationHandler = new JwksValidationHandler();
          return oauthService
            .loadDiscoveryDocumentAndTryLogin()
            .then(() => {
              if (oauthService.hasValidAccessToken()) {
                oauthService.setupAutomaticSilentRefresh();
              } else {
                oauthService.initImplicitFlow();
              }
            });
        });
    }
  };
}

registerLocaleData(localeEnBe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthenticationModule,
    ClickOutsideModule,
    TranslateModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      enableHtml: true
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.forDeskApiConfig.host,
        environment.identityApiConfig.host,
        environment.clientApiConfig.host,
        ],
        sendAccessToken: true
      }
    }),
    CoreModule,
    PagesModule,
    FordeskApiModule,
    IdentityApiModule
  ],
  providers: [
    {
      provide: JwtHelperService, useValue: new JwtHelperService()
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500, verticalPosition: "top" }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue:
      {
        showDelay: 500,
        disableTooltipInteractivity: true
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-BE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHTTPErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthRedirectInterceptor,
      multi: true
    },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: LOCALE_ID, useValue: "en-BE" },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [OAuthService, TranslationService, Location],
      multi: true
    },
    { provide: FORDESK_BASE_PATH, useValue: environment.forDeskApiConfig.host },
    { provide: IDENTITY_BASE_PATH, useValue: environment.identityApiConfig.host }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias("lineawesome", "la");
    matIconRegistry.registerFontClassAlias("fontawesome", "fa");
  }
}
