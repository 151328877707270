<div class="row">
  <div class="col-8 input-group date-wrapper">
    <input class="form-control"
           ngbDatepicker
           (input)="onInput($event)"
           [placement]="placement"
           #datePicker="ngbDatepicker"
           [placeholder]="'PLACEHOLDERS.DATE_FORMAT' | translate"
           [tabIndex]="tabIndex"
           (click)="datePicker.close()"
           [value]="selectedDateAsString"
           (blur)="onTextChanged($event)"
           (change)="onTextChanged($event)"
           (dateSelect)="onDateSelected($event)"
           [readonly]="isDisabled"
           [startDate]="startDate"
           [minDate]="minDate"
           [maxDate]="maxDate"
           [footerTemplate]="setTodayButton"
           [(ngModel)]="ngbDate" />
    <div class="input-group-append">
      <button class="btn"
              type="button"
              [disabled]="isDisabled"
              (click)="datePicker.open()">
        <i class="la la-calendar"></i>
      </button>
    </div>
  </div>
  <div class="col-4">
    <ngb-timepicker #timePicker
                    [(ngModel)]="selectedTime"
                    [seconds]="false"
                    [spinners]="false"
                    [disabled]="isDisabled"
                    (ngModelChange)="onTimeChanged()"
                    timepickerHelper
                    [dateControl]="this"
                    [hoursMinutesControl]="this.timePicker">
    </ngb-timepicker>
  </div>
</div>

<ng-template #setTodayButton>
  <button class="btn btn-primary m-btn w-100"
          type="button"
          (click)="todaySelected(); datePicker.toggle()"
          [disabled]="isDisabled || minDateMoreThanToday()">{{'PLACEHOLDERS.TODAY' | translate}}</button>
</ng-template>