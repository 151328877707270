<a href="javascript:;"
   class="m-nav__link m-dropdown__toggle"
   id="m_topbar_notification_icon"
   (click)="navigateToNotifications()">
  <span *ngIf="notificationCount && notificationCount > 0"
        class="m-nav__link-badge m-badge m-badge--danger"><b>{{ notificationCount < 100 ? notificationCount : '99+' }}</b></span>
  <span class="m-nav__link-icon"
        [ngClass]="animateShake">
    <span class="m-nav__link-icon-wrapper">
      <i class="flaticon-alarm"></i>
    </span>
  </span>
</a>
