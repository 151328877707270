<div class="form-group row">
  <label class="col-form-label col-3 py-0">{{id | translate}}</label>
  <div *ngIf="isPorts; else warehouseBlock"
       class="col-9">
    <ng-select [items]="items$ | async"
               #ngSelect
               [ngbTooltip]="ngSelect.element?.firstChild?.firstChild?.childNodes[1]?.innerText"
               (ngModelChange)="cdRef.detectChanges()"
               [disableTooltip]="ngSelect.isOpen || formControl?.value == null"
               [openDelay]="300"
               placement="bottom"
               bindValue="id"
               bindLabel="name"
               [virtualScroll]="true"
               [loading]="loading"
               [formControl]="formControl"
               placeholder="{{ 'TYPEAHEAD_3_MIN' | translate }}"
               class="form-control m-input"
               [typeahead]="searchInput$"
               [class.is-invalid]="formControl.invalid"
               typeAheadHelper>
      <ng-template ng-label-tmp
                   let-item="item">
        <span class="ng-value-label">{{item.unloCode}} - {{item.name}} ({{item.countryName}})</span>
      </ng-template>

      <ng-template ng-option-tmp
                   let-item="item">
        <strong>{{ item.name }}</strong><br />
        <span>{{ item.countryName }}</span><br />
        <span>{{ item.unloCode }}</span>
      </ng-template>

    </ng-select>
    <type-ahead-error *ngIf="formControl.invalid"
                      [name]="id | translate"></type-ahead-error>
  </div>

  <ng-template #warehouseBlock>
    <div class="input-group col-7">
      <ng-select [items]="items$ | async"
                 #ngSelect1
                 [ngbTooltip]="ngSelect1.element?.firstChild?.firstChild?.childNodes[1]?.innerText"
                 (ngModelChange)="cdRef.detectChanges()"
                 [disableTooltip]="ngSelect1.isOpen || formControl?.value == null"
                 [openDelay]="300"
                 placement="bottom"
                 bindValue="id"
                 [virtualScroll]="true"
                 [loading]="loading"
                 [formControl]="formControl"
                 placeholder="{{ 'TYPEAHEAD_3_MIN' | translate }}"
                 class="form-control m-input"
                 [class.is-invalid]="formControl.invalid"
                 typeAheadHelper>
        <ng-template ng-label-tmp
                     let-item="item">
          <address-label [item]="item"></address-label>
        </ng-template>
        <ng-template ng-option-tmp
                     let-item="item">
          <address-option [item]="item"></address-option>
        </ng-template>
      </ng-select>
      <type-ahead-error *ngIf="formControl.invalid"></type-ahead-error>
    </div>
  </ng-template>
</div>
