<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{ "OPERATIONAL.EDIT.BOOKINGREFERENCES" | translate }}</label>
  <div class="col-9">
    <textarea class="form-control m-input"
              [class.is-invalid]="submitted && formControl.errors"
              [formControl]="formControl"
              trimStart
              cols="1"
              maxlength="256"
              rows="5"></textarea>

    <div class="form-field-footer">
      <text-to-label [control]="formControl"></text-to-label>
      <char-counter [control]="formControl"
                    [maxCharCount]="256">
      </char-counter>
    </div>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.maxlength"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.BOOKINGREFERENCES' | translate, max: 256 }">
        VALIDATION.MAX_LENGTH
      </div>
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.BOOKINGREFERENCES' | translate }">
        VALIDATION.REQUIRED
      </div>
    </div>
  </div>
</div>
