import { AuthConfig } from "angular-oauth2-oidc";
import { EnvironmentConfig } from "./environment-config";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const authentication: AuthConfig = {
  issuer: "https://security-staging.fordesk.net",
  redirectUri: window.location.origin,
  clientId: "forwarding_client",
  scope: "openid profile forwarding_resources tenant_api client_api identity_api notification_api prospects_api sales_api otl_api",
  postLogoutRedirectUri: "https://security-staging.fordesk.net/account/login",
  loginUrl: "https://security-staging.fordesk.net/account/login",
  requireHttps: false,
  silentRefreshRedirectUri: window.location.origin + "/assets/silent-refresh.html",
  silentRefreshShowIFrame: false,
};

export const environment: EnvironmentConfig = {
  syncfusionAspNetCoreLicenseKey: "#{SyncfusionAspNetCoreLicenseKey}#",
  production: false,
  authentication: authentication,
  name: "STAGING",
  integrationSettings: {
    isCustomsEnabled: true,
    isCreateOutgoingInvoiceEnabled: true,
  },
  notificationsRefreshRate: 60000,
  applicationUrl: "https://staging.fordesk.net",
  clientApiConfig: {
    host: "https://staging.fordesk.net",
    baseUrl: "api"
  },
  identityApiConfig: {
    host: "https://security-staging.fordesk.net",
    baseUrl: "api"
  },
  forDeskApiConfig: {
    host: "https://api-staging.fordesk.net",
    baseUrl: "api"
  }
};
