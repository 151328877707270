import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, share } from "rxjs/operators";
import { ClientTenantConfigurationDTO, ClientTenantConfigurationService } from "../swagger-gen/fordesk";
@Injectable({ providedIn: "root" })
export class TenantConfigurationService {
  private clientTenantConfig: ClientTenantConfigurationDTO = {
    inttraSettings: { isEnabled: false, isShippingInstructionsEnabled: false, isBookingEnabled: false, isVgmEnabled: false },
    ediSettings: { isEnabled: false, isShippingInstructionsEnabled: false, isBookingEnabled: false, isVgmEnabled: false },
    generalSettings: { logoFileName: "logo_fordesk.png" },
    financeSettings: { applicationCurrencyId: 1 },
    operationalFileSettings: { defaultCurrencyId: 1, isCurrencySelectionEnabled: false },
    quoteFileSettings: { defaultValidDaysCount: 30, isCurrencyChoiceEnabled: false, defaultCurrencyId: 1 },
    courierServicesTenantConfiguration: { isEnabled: false },
    externalContainersSearchSetting: { isExternalContainerImportEnabled: false },
    containerPickupSettings: { activePorts: [] },
  };

  private initialized: boolean = false;
  private configLoadingObservable: Observable<ClientTenantConfigurationDTO> | null = null;

  constructor(
    private clientTenantConfigurationService: ClientTenantConfigurationService
  ) { }

  public initialize(): Observable<ClientTenantConfigurationDTO> {
    if (this.initialized) {
      return of(this.clientTenantConfig);
    }

    if (this.configLoadingObservable) {
      return this.configLoadingObservable;
    }

    this.configLoadingObservable = this.clientTenantConfigurationService.getClientTenantConfiguration()
      .pipe(
        catchError(() => {
          return of(this.clientTenantConfig);
        }),
        map(loadedConfig => {
          this.clientTenantConfig = loadedConfig;
          this.initialized = true;
          this.configLoadingObservable = null;

          return loadedConfig;
        }),
        share(),
      );

    return this.configLoadingObservable;
  }

  public get(): ClientTenantConfigurationDTO {
    return this.clientTenantConfig;
  }
}
