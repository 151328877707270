<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{id | translate}}</label>
  <div class="col-9"
       [class.ng-invalid-input-group]=" submitted && formControl.errors">
    <div [formGroup]="form"
         class="date-input">
      <date-time-input [maxDate]="getMaxDate()"
                       [formControlName]="field.key"></date-time-input>
    </div>
  </div>
</div>
