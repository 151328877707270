<div class="form-group row ">
  <label class="col-form-label col-3">{{ "OPERATIONAL.GENERAL.HOUSEBLNR" | translate }}</label>
  <div class="col-9">
    <textarea class="form-control m-input"
              [formControl]="formControl"
              trimStart
              cols="1"
              maxlength="256"
              rows="5"></textarea>
    <char-counter [control]="formControl"
                  [maxCharCount]="256"></char-counter>
  </div>
</div>
