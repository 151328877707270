/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DetailedResponse } from '../model/detailedResponse';
import { FoundOperationalFileTransportStop } from '../model/foundOperationalFileTransportStop';
import { OperationalFileTransportStopDetailsDTO } from '../model/operationalFileTransportStopDetailsDTO';
import { PagedResponseFoundOperationalFileTransportStop } from '../model/pagedResponseFoundOperationalFileTransportStop';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OperationalFileTransportStopsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a transport stops
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTransportStop(operationalFileId: number, operationalFileTransportId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<DetailedResponse>;
    public addTransportStop(operationalFileId: number, operationalFileTransportId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<DetailedResponse>>;
    public addTransportStop(operationalFileId: number, operationalFileTransportId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<DetailedResponse>>;
    public addTransportStop(operationalFileId: number, operationalFileTransportId: number, body?: OperationalFileTransportStopDetailsDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling addTransportStop.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling addTransportStop.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<DetailedResponse>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete a transport stops
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param operationalFileTransportStopId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling deleteTransportStop.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling deleteTransportStop.');
        }

        if (operationalFileTransportStopId === null || operationalFileTransportStopId === undefined) {
            throw new Error('Required parameter operationalFileTransportStopId was null or undefined when calling deleteTransportStop.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/${encodeURIComponent(String(operationalFileTransportStopId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a default transport stops for an operational file
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultOperationalFileTransportStops(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundOperationalFileTransportStop>>;
    public getDefaultOperationalFileTransportStops(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundOperationalFileTransportStop>>>;
    public getDefaultOperationalFileTransportStops(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundOperationalFileTransportStop>>>;
    public getDefaultOperationalFileTransportStops(operationalFileId: number, operationalFileTransportId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getDefaultOperationalFileTransportStops.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling getDefaultOperationalFileTransportStops.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<FoundOperationalFileTransportStop>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/default`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a default transport stops for an operational file of type warehousing
     * 
     * @param operationalFileId 
     * @param warehouseTransportType 
     * @param operationalFileTransportId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultOperationalFileTransportStopsForWarehousing(operationalFileId: number, warehouseTransportType: number, operationalFileTransportId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundOperationalFileTransportStop>>;
    public getDefaultOperationalFileTransportStopsForWarehousing(operationalFileId: number, warehouseTransportType: number, operationalFileTransportId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundOperationalFileTransportStop>>>;
    public getDefaultOperationalFileTransportStopsForWarehousing(operationalFileId: number, warehouseTransportType: number, operationalFileTransportId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundOperationalFileTransportStop>>>;
    public getDefaultOperationalFileTransportStopsForWarehousing(operationalFileId: number, warehouseTransportType: number, operationalFileTransportId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getDefaultOperationalFileTransportStopsForWarehousing.');
        }

        if (warehouseTransportType === null || warehouseTransportType === undefined) {
            throw new Error('Required parameter warehouseTransportType was null or undefined when calling getDefaultOperationalFileTransportStopsForWarehousing.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling getDefaultOperationalFileTransportStopsForWarehousing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<FoundOperationalFileTransportStop>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/default/${encodeURIComponent(String(warehouseTransportType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a transport stops for an operational file
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileTransportStopsByOperationalFile(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundOperationalFileTransportStop>>;
    public getOperationalFileTransportStopsByOperationalFile(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundOperationalFileTransportStop>>>;
    public getOperationalFileTransportStopsByOperationalFile(operationalFileId: number, operationalFileTransportId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundOperationalFileTransportStop>>>;
    public getOperationalFileTransportStopsByOperationalFile(operationalFileId: number, operationalFileTransportId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileTransportStopsByOperationalFile.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling getOperationalFileTransportStopsByOperationalFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<FoundOperationalFileTransportStop>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a transport stops
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param operationalFileTransportStopId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileTransportStopDetailsDTO>;
    public getTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileTransportStopDetailsDTO>>;
    public getTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileTransportStopDetailsDTO>>;
    public getTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getTransportStop.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling getTransportStop.');
        }

        if (operationalFileTransportStopId === null || operationalFileTransportStopId === undefined) {
            throw new Error('Required parameter operationalFileTransportStopId was null or undefined when calling getTransportStop.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileTransportStopDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/${encodeURIComponent(String(operationalFileTransportStopId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search a transport stops
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTransportStops(operationalFileId: number, operationalFileTransportId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOperationalFileTransportStop>;
    public searchTransportStops(operationalFileId: number, operationalFileTransportId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOperationalFileTransportStop>>;
    public searchTransportStops(operationalFileId: number, operationalFileTransportId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOperationalFileTransportStop>>;
    public searchTransportStops(operationalFileId: number, operationalFileTransportId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling searchTransportStops.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling searchTransportStops.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundOperationalFileTransportStop>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update a transport stops
     * 
     * @param operationalFileId 
     * @param operationalFileTransportId 
     * @param operationalFileTransportStopId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public updateTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public updateTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, body?: OperationalFileTransportStopDetailsDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public updateTransportStop(operationalFileId: number, operationalFileTransportId: number, operationalFileTransportStopId: number, body?: OperationalFileTransportStopDetailsDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateTransportStop.');
        }

        if (operationalFileTransportId === null || operationalFileTransportId === undefined) {
            throw new Error('Required parameter operationalFileTransportId was null or undefined when calling updateTransportStop.');
        }

        if (operationalFileTransportStopId === null || operationalFileTransportStopId === undefined) {
            throw new Error('Required parameter operationalFileTransportStopId was null or undefined when calling updateTransportStop.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transports/${encodeURIComponent(String(operationalFileTransportId))}/transport-stops/${encodeURIComponent(String(operationalFileTransportStopId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
