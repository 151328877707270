import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDTO } from '../../../core/services/swagger-gen/fordesk';
import { CreatedEntitesListData } from "../../../core/models/dialogs/created-entities-list-data";

@Component({
  selector: 'created-entities-list-dialog',
  templateUrl: './created-entities-list-dialog-component.html',
  styleUrls: ['./created-entities-list-dialog-component.scss']
})
export class CreatedEntitiesListDialogComponent implements OnInit, AfterViewInit {

  public contactPersonTypes: LookupDTO[] = [];
  public languages: LookupDTO[] = [];

  public cachableTypes: string[] = ["contactPersonTypeId"];
  constructor(
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CreatedEntitiesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreatedEntitesListData) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  public cancel() {
    this.dialogRef.close(null);
  }

  public navigate(selectedId: number) {
    const url = this.data.navigationUrlWithId.replace('{id}', selectedId.toString());
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
}
