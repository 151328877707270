import { KeyValue } from "@angular/common";

export class CreatedEntitesListData {
  public entities: Entitiy[]
  public entitesListLabel: string;
  public navigationUrlWithId: string;
}

export class Entitiy {
  constructor(label: string, value: number, entityDetails: KeyValue<string, string>[] = null) {
    this.label = label
    this.value = value;
    this.entityDetails = entityDetails;
  }
  public label: string;
  public value: number;
  public entityDetails: KeyValue<string, string>[];
}
