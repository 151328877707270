<div>
  <h4 class="wrap-text"
      mat-dialog-title>{{ data.dialogTitle }}</h4>
  <div mat-dialog-content>
    <p class="wrap-text"
       *ngIf="!data.htmlText">{{ data.confirmationText }}</p>
    <p class="wrap-text"
       *ngIf="data.htmlText"
       [innerHTML]="data.confirmationText"></p>
  </div>
</div>
<div align="end"
     class="mt-4">
  <button (click)="confirm()"
          class="m-btn btn btn-primary">
    <i class="la la-check"></i>
    <span>{{ (data.actionYes ? data.actionYes : "ACTIONS.YES" | translate) }}</span>
  </button>
  <button (click)="cancel()"
          class="m-btn btn btn-secondary">
    <i class="la la-remove"></i>
    <span>{{ (data.actionNo ? data.actionNo : "ACTIONS.NO" | translate) }}</span>
  </button>
</div>
