<div class="form-group row ">
  <label [class.required]="showIsRequired"
         class="col-form-label col-3 py-0">{{ "FINANCE.EXCHANGE_RATE" | translate }}</label>
  <div class="col-9">
    <exchange-rate [formControl]="formControl"
                   [formlyAttributes]="field"
                   name="exchangerate"
                   id="exchangerate"
                   [invalid]="submitted && formControl.errors"></exchange-rate>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: 'OPERATIONAL.GENERAL.EXCHANGE_RATE' | translate }">
        VALIDATION.REQUIRED
      </div>
      <!-- Uncomment if the numbersOnlyPrecision directive is removed in  exchange-rate -->
      <!-- <div *ngIf="formControl.errors.pattern">
        {{'OPERATIONAL.GENERAL.VALIDATORS.EXCHANGE_RATE_PRECISION' | translate }}
      </div> -->
    </div>
  </div>
</div>
