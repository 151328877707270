import { ConfigModel } from "../core/interfaces/config";
import { forkJoin, Subject } from "rxjs";
import { ForwardingRole } from "../core/auth/roles/roles.enum";
import { RoleService } from "../core/auth/roles/role.service";
import { UPLOAD_UBL_DIALOG } from "../core/services/helpers/dialog-identifiers";
import { delay } from "rxjs/operators";
import { TenantConfigurationService } from "../core/services/client-tenant-configuration/tenant-config.service";
import { ClientTenantConfigurationDTO } from "../core/services/swagger-gen/fordesk";
import { QuoteDetailMode } from "../core/enumerations/quote-detail-mode.enum";


export class MenuConfig implements ConfigModel {
  public config: any = {};

  constructor(
    public roleService: RoleService,
    private tenantConfigurationService: TenantConfigurationService,
    updateUi: Subject<void>,
  ) {
    this.config = this.initConfig();

    forkJoin({
      roles: this.roleService.getRolesFromUserProfile$(),
      tenantConfig: this.tenantConfigurationService.initialize(),
    })
      .pipe(delay(0))
      .subscribe(
        ({ roles, tenantConfig }) => {
          this.buildMenuFromUserProfile(roles, tenantConfig);
          updateUi.next();
        }
      );
  }

  private buildMenuFromUserProfile(roles: string[], tenantConfig: ClientTenantConfigurationDTO): void {
    if (tenantConfig?.moduleSettings?.dashboards) {
      this.withDashboardRoutes();
    }

    if (this.roleService.userHasRole(roles, ForwardingRole.FordeskSales)) {
      this.withSalesRoutes(roles);
    }

    this.withOperationalRoutes();

    if (
      this.roleService.userHasRole(
        roles,
        ForwardingRole.ForwardingFinancialUser
      )
    ) {
      this.withFinanceRoutes();
    }

    if (
      this.roleService.userHasRole(roles, ForwardingRole.FordeskMasterdataUser)
    ) {
      this.withMasterdataRoutes();
    }

    if (
      this.roleService.userHasRole(
        roles,
        ForwardingRole.ForwardingReportingUser
      )
    ) {
      this.withReportingRoutes();
    }
    if (
      this.roleService.userHasRole(
        roles,
        ForwardingRole.ForwardingUserManagement
      )
    ) {
      this.withConfigRoutes(
        roles
      );
    }

    this.config = this.build();
  }

  private initConfig() {
    return {
      header: {
        self: {},
        items: [],
      },
      aside: {
        self: {},
        items: [],
      },
    };
  }

  private addSectionToHeader(section: any) {
    this.config.header.items.push(section);
  }

  public withDashboardRoutes() {
    const operationalFileSection = {
      title: "Dashboards",
      root: true,
      icon: "la la-chart-bar",
      translate: "MENU.DASHBOARDS",
      page: "/dashboards",
    };

    this.addSectionToHeader(operationalFileSection);
    return this;
  }

  public withOperationalRoutes() {
    const operationalFileSection = {
      title: "Operational",
      root: true,
      icon: "la la-ship",
      toggle: "click",
      translate: "MENU.ACTIONS",
      page: "/operational",
      submenu: {
        type: "classic",
        alignment: "left",
        width: "200px",
        items: [
          {
            title: "New",
            translate: "MENU.NEW",
            icon: "la la-plus",
            page: "/operational/files/new",
          },
          {
            title: "Search",
            translate: "MENU.SEARCH",
            icon: "la la-search",
            page: "/operational/files",
          },
        ],
      },
    };

    this.addSectionToHeader(operationalFileSection);
    return this;
  }

  public withFinanceRoutes() {
    const financeSection = {
      title: "Finance",
      root: true,
      icon: "la la-euro",
      toggle: "click",
      translate: "MENU.REPORTS",
      page: "/finance",
      submenu: {
        type: "classic",
        // width: '200px',
        alignment: "left",
        items: [
          {
            title: "New outgoing invoice",
            icon: "la la-plus",
            page: "/finance/outgoing-invoices/new",
            translate: "MENU.FINANCE.NEW_OUTGOING_INVOICE",
          },
          {
            title: "Outgoing invoices",
            icon: "la la-arrow-circle-up",
            page: "/finance/outgoing-invoices/list",
            translate: "MENU.FINANCE.OUTGOING_INVOICES",
          },
          {
            title: "New incoming invoice",
            icon: "la la-plus",
            page: "/finance/incoming-invoices/new",
            translate: "MENU.FINANCE.NEW_INCOMING_INVOICE",
          },
          {
            title: "Incoming invoices",
            icon: "la la-arrow-circle-down",
            page: "/finance/incoming-invoices/list",
            translate: "MENU.FINANCE.INCOMING_INVOICES",
          },
          {
            title: "Upload incoming invoice",
            icon: "la la-upload",
            dialog: UPLOAD_UBL_DIALOG,
            translate: "MENU.FINANCE.NEW_INCOMING_INVOICE",
          },
          {
            title: "Import/Export",
            icon: "la la-archive",
            page: "/finance/import-export",
            translate: "MENU.FINANCE.IMPORT_EXPORT",
          },
        ],
      },
    };

    this.addSectionToHeader(financeSection);
    return this;
  }

  public withMasterdataRoutes() {
    const masterDataSection = {
      title: "Masterdata",
      root: true,
      icon: "la la-cogs",
      toggle: "click",
      translate: "MENU.MASTERDATA",
      page: "/masterdata",
      submenu: {
        type: "mega",
        width: "470px",
        alignment: "center",
        columns: [
          {
            heading: { heading: false },
            items: [
              {
                title: "Customers",
                icon: "la la-users",
                page: "/masterdata/companies/customers",
              },
              {
                title: "Suppliers",
                icon: "la la-truck",
                page: "/masterdata/companies/suppliers",
              },
              {
                title: "Locations",
                icon: "la la-location-arrow",
                page: "/masterdata/locations",
              },
              {
                title: "Languages",
                icon: "la la-language",
                page: "/masterdata/languages",
              },
              {
                title: "Places",
                icon: "la la-map-marker",
                page: "/masterdata/places",
              },
              {
                title: "Countries",
                icon: "la la-globe",
                page: "/masterdata/countries",
              },
              {
                title: "Ports",
                icon: "la la-flag-o",
                page: "/masterdata/ports",
              },
              {
                title: "Terminals",
                icon: "la la-exchange",
                page: "/masterdata/terminals",
              },
              {
                title: "Depots",
                icon: "la la-download",
                page: "/masterdata/depots",
              },
              {
                title: "Vessels",
                icon: "la la-ship",
                page: "/masterdata/vessels",
              },
              {
                title: "Invoice comments",
                icon: "la la-comments",
                page: "/masterdata/invoicecomments",
              },
              {
                title: "Step templates",
                icon: "la la-list-ol",
                page: "/masterdata/step-templates",
              },
              {
                title: "Units of measurement",
                icon: "la la-balance-scale",
                page: "/masterdata/units-of-measurement",
              },
              {
                title: "OCR settings",
                icon: "la la-stream",
                page: "/masterdata/ocr-settings",
              }
            ],
          },
          {
            heading: { heading: false },
            items: [
              {
                title: "Container ISO codes",
                icon: "la la-codepen",
                page: "/masterdata/containerisocodes",
              },
              {
                title: "HS codes",
                icon: "la la-code",
                page: "/masterdata/hscodes",
              },
              {
                title: "Incoterms",
                icon: "la la-copy",
                page: "/masterdata/incoterms",
              },
              {
                title: "Currencies",
                icon: "la la-money",
                page: "/masterdata/currencies",
              },
              {
                title: "Contact person types",
                icon: "la la-credit-card",
                page: "/masterdata/contactpersontypes",
              },
              {
                title: "IMO classifications",
                icon: "la la-exclamation-triangle",
                page: "/masterdata/imoclassifications",
              },
              {
                title: "Seal number types",
                icon: "la la-lock",
                page: "/masterdata/sealnumbertypes",
              },
              {
                title: "Party types",
                icon: "la la-wechat",
                page: "/masterdata/partytypes",
              },
              {
                title: "Package types",
                icon: "la la-cube",
                page: "/masterdata/packagetypes",
              },
              {
                title: "Activity codes",
                icon: "la la-list",
                page: "/masterdata/activity-codes",
              },
              {
                title: "Steps",
                icon: "la la-list-ol",
                page: "/masterdata/steps",
              },
              {
                title: "Standard transport stop texts",
                icon: "la la-truck-loading",
                page: "/masterdata/standard-texts",
              },
              {
                title: "Email texts",
                icon: "la la-envelope-open-text",
                page: "/masterdata/email-templates",
              },
            ],
          },
        ],
      },
    };

    this.addSectionToHeader(masterDataSection);
    return this;
  }

  public withReportingRoutes() {
    const reportingSection = {
      title: "Reporting",
      root: true,
      icon: "la la-area-chart",
      toggle: "click",
      translate: "MENU.APPS",
      page: "/reporting",
      submenu: {
        type: "classic",
        width: "300px",
        alignment: "left",
        items: [
          {
            title: "Operational summary per user",
            icon: "la la-user",
            page: "/reporting/operational-summary-per-user",
            translate: "MENU.REPORTING.OPERATIONAL_SUMMARY_PER_USER",
          },
          {
            title: "Open budget per operational file",
            icon: "la la-folder-open",
            page: "/reporting/open-budget-per-operational-file",
            translate: "MENU.REPORTING.OPEN_BUDGET_PER_OPERATIONAL_FILE",
          },
          {
            title: "Budget details per operational file",
            icon: "la la-folder-open",
            page: "/reporting/budget-details-per-operational-file",
            translate: "MENU.REPORTING.BUDGET_DETAILS_PER_OPERATIONAL_FILE",
          },
          {
            title: "Budget details per quote",
            icon: "la la-file-text",
            page: "/reporting/budget-details-per-quote",
            translate: "MENU.REPORTING.BUDGET_DETAILS_PER_QUOTE",
          },
          {
            title: "Yearly budget overview",
            icon: "la la-calculator",
            page: "/reporting/yearly-budget-overview",
            translate: "MENU.REPORTING.YEARLY_BUDGET_OVERVIEW",
          },
          {
            title: "Outgoing invoices per operational file",
            icon: "la la-folder-open",
            page: "/reporting/outgoing-invoices-per-operational-file",
            translate: "MENU.REPORTING.OUTGOING_INVOICES_PER_OPERATIONAL_FILE",
          },
          {
            title: "Operational files overview",
            icon: "la la-ship",
            page: "/reporting/operational-files-overview",
            translate: "MENU.REPORTING.OPERATIONAL_FILES_OVERVIEW",
          },
          {
            title: "Budget per activity codes",
            icon: "la la-calculator",
            page: "/reporting/budget-per-activity-codes",
            translate: "MENU.REPORTING.BUDGET_PER_ACTIVITY_CODE",
          },
        ],
      },
    };

    this.addSectionToHeader(reportingSection);
    return this;
  }

  public withConfigRoutes(
    roles: string[]
  ) {
    const configSection = {
      title: "Config",
      root: true,
      icon: "la la-gear",
      toggle: "click",
      translate: "MENU.APPS",
      submenu: {
        type: "classic",
        width: "200px",
        alignment: "left",
        items: [],
      },
    };

    if (
      this.roleService.userHasRole(
        roles,
        ForwardingRole.ForwardingUserManagement
      )
    ) {
      configSection.submenu.items.push({
        title: "Users",
        icon: "la la-user",
        translate: "MENU.APPS",
        page: "/config/users",
      });
    }

    if (
      this.roleService.userHasRole(roles, ForwardingRole.DiflexmoAdmin) ||
      this.roleService.userHasRole(roles, ForwardingRole.ForwardingAdmin)
    ) {
      configSection.submenu.items.push({
        title: "Synchronization",
        icon: "las la-sync",
        translate: "MENU.APPS",
        page: "/config/reporting-synchronization",
      });
    }

    if (
      this.roleService.userHasRole(roles, ForwardingRole.DiflexmoAdmin) ||
      this.roleService.userHasRole(roles, ForwardingRole.ForwardingAdmin)
    ) {
      configSection.submenu.items.push({
        title: "Integrations",
        icon: "las la-plug",
        translate: "MENU.APPS",
        page: "/config/integrations",
      });
    }

    this.addSectionToHeader(configSection);
    return this;
  }

  public withSalesRoutes(roles: string[]) {
    const salesSection = {
      title: "Sales",
      root: true,
      icon: "la la-users",
      toggle: "click",
      translate: "MENU.SALES.SALES",
      submenu: {
        type: "classic",
        width: "200px",
        alignment: "left",
        items: []
          .concat(this.withQuotesRoutes(roles))
          .concat(this.withProspectsRoutes(roles)),
      },
    };

    this.addSectionToHeader(salesSection);
    return this;
  }

  private withQuotesRoutes(roles: string[]): any[] {
    return this.roleService.userHasRole(
      roles,
      ForwardingRole.ForwardingQuoteUser
    )
      ? [
        {
          title: "New quote for a customer",
          icon: "la la-plus",
          page: `/quotes/new/${QuoteDetailMode.ForCustomer}`,
          translate: "MENU.SALES.NEW_QUOTE_FOR_CUSTOMER",
        },
        {
          title: "New quote for a prospect",
          icon: "la la-plus",
          page: `/quotes/new/${QuoteDetailMode.ForProspect}`,
          translate: "MENU.SALES.NEW_QUOTE_FOR_PROSPECT",
        },
        {
          title: "Quotes",
          icon: "la la-file-text",
          page: "/quotes/list",
          translate: "MENU.SALES.QUOTES",
        },
      ]
      : [];
  }

  private withProspectsRoutes(roles: string[]): any[] {
    return this.roleService.userHasRole(roles, ForwardingRole.FordeskProspects)
      ? [
        {
          title: "New prospect",
          icon: "la la-plus",
          page: "/prospects/new",
          translate: "MENU.SALES.NEW_PROSPECT",
        },
        {
          title: "Prospects",
          icon: "la la-user-plus",
          page: "/prospects/list",
          translate: "MENU.SALES.PROSPECTS",
        },
      ]
      : [];
  }

  public build() {
    return this.config;
  }
}
