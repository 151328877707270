export const prospects_details_locale = {
  TITLE_NEW: "New prospect",
  TITLE: "Prospect details",
  NOTES: "Notes",
  COMPANY_NAME: "Company name",
  STATUS: "Status",
  CONTACT_PERSON: "Contact person",
  NAME: "Name",
  TELEPHONE_NR: "Telephone nr.",
  EMAIL: "Email",
  CONTACTS: "Contacts",
  FIRST_CONTACTED: "First contacted",
  MOST_RECENT: "Most recent",
  FUTURE: "Future",
  NOW: "Now",
  MESSAGES: {
    ADDRESS_INFO_NOT_COMPLETED: "Address information is not correct. Please complete it first.",
  },
};
export const new_prospects_locale = {
  TITLE: "New prospect",
};
