<ng-select [items]="mappedOptions"
           #ngSelect
           [ngbTooltip]="ngSelect.element?.firstChild?.firstChild?.childNodes[1]?.innerText"
           (ngModelChange)="cdRef.detectChanges()"
           [disableTooltip]="ngSelect.isOpen || formControl?.value == null"
           [openDelay]="300"
           placement="bottom"
           bindValue="id"
           [id]="id"
           [virtualScroll]="true"
           [loading]="loading"
           bindLabel="description"
           [formControl]="formControl"
           [searchFn]="vesselSearch"
           (change)="selectionChanged($event)"
           (search)="onSearched($event)">
</ng-select>
