<div class="m-grid__item m-grid__item--fluid m-grid m-error-1" style="background-image: url(./assets/app/media/img/error/bg1.jpg);">
  <div class="m-error_container">
    <span class="m-error_number">
      <h1>Not found</h1>
    </span>
    <p class="m-error_desc">
      Sorry, we didn't find what you were looking for...
    </p>
    <p class="m-error_desc">
      Would you like to return <strong><a routerLink="/">home?</a></strong>
    </p>
  </div>
</div>