import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule, DecimalPipe, DatePipe } from "@angular/common";
import { BootstrapModule } from "./bootstrap/bootstrap.module";
import { NgbDateNativeAdapter, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "../../material/material.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SpinnerButtonModule } from "../partials/content/general/spinner-button/spinner-button.module";
import { PortletModule } from "../partials/content/general/portlet/portlet.module";
import { CoreModule } from "../../core/core.module";
import { TranslateModule } from "@ngx-translate/core";
import { SafePipe } from "../../core/pipes/safe.pipe";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { RouterModule } from "@angular/router";
import { ImageViewerModule } from "ngx-image-viewer";
import { UtilsService } from "../../core/services/utils.service";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { LoadingOverlayComponent } from "../../core/components/loading-overlay/loading-overlay.component";
import { DecimalFormatterService } from "../../core/helpers/decimal-formatter.service";
import { MatDialog } from "@angular/material/dialog";
import { DateInputComponent } from "./date-input/date-input.component";
import { DateTimeInputComponent } from "./date-time-input/date-time-input.component";
import { CharCounterComponent } from "./char-counter/char-counter.component";
import { TextToLabelComponent } from './text-to-label/text-to-label.component';
import { SortHeaderCombinedComponent } from "./sort-header-combined/sort-header-combined.component";
import { HeaderCombinedPartComponent } from "./sort-header-combined/header-combined-part/header-combined-part.component";
import { NumberInputComponent } from "./number-input/number-input.component";
import { PromoteProspectCompanyComponent } from "./promote-prospect-company/promote-prospect-company";
import { CompanyDuplicatesDialogComponent } from "./company-duplicates-dialog/company-duplicates-dialog";

@NgModule({
  declarations: [
    LoadingOverlayComponent,
    DateInputComponent,
    DateTimeInputComponent,
    NumberInputComponent,
    CharCounterComponent,
    TextToLabelComponent,
    SortHeaderCombinedComponent,
    HeaderCombinedPartComponent,
    PromoteProspectCompanyComponent,
    CompanyDuplicatesDialogComponent,
  ],
  imports: [
    CommonModule,
    BootstrapModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerButtonModule,
    PortletModule,
    CoreModule,
    TranslateModule,
    NgSelectModule,
    FormlyBootstrapModule,
    RouterModule,
    ImageViewerModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    BootstrapModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerButtonModule,
    PortletModule,
    CoreModule,
    TranslateModule,
    NgSelectModule,
    FormlyBootstrapModule,
    RouterModule,
    ImageViewerModule,
    DragDropModule,
    NgxExtendedPdfViewerModule,
    LoadingOverlayComponent,
    DateInputComponent,
    DateTimeInputComponent,
    NumberInputComponent,
    CharCounterComponent,
    TextToLabelComponent,
    SortHeaderCombinedComponent,
    HeaderCombinedPartComponent,
    PromoteProspectCompanyComponent,
    CompanyDuplicatesDialogComponent,
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        NgbDateNativeAdapter,
        NgbInputDatepicker,
        DecimalPipe,
        DatePipe,
        SafePipe,
        UtilsService,
        MatDialog,
        DecimalFormatterService
      ]
    };
  }
}
