import { ConfirmDialog } from "./confirm/confirm.component";
import { NgModule } from "@angular/core";
import { ConfirmOutgoingInvoiceLinesComponent } from "./confirm-outgoing-invoice-lines/confirm-outgoing-invoice-lines.component";
import { CommonModule } from "@angular/common";
import { ConfirmDatePickerComponent } from "./confirm-date-picker/confirm-date-picker.component";
import { ConfirmDropDownWithDescriptionDialog } from "./confirm-drop-down-with-description/confirm-drop-down-with-description.component";
import { VgmConfirmComponent } from "./vgm-confirm/vgm-confirm.component";
import { ConfirmWithReasonDialog } from "./confirm-with-reason/confirm-with-reason.component";
import { ConfirmPartyDropDownComponent } from "./confirm-party-drop-down/confirm-party-drop-down.component";
import { SharedModule } from "../shared/shared.module";
import { ForwardingComponentModule } from "../../core/components/forwarding-component.module";
import { UploadUblDialog } from "./upload-ubl-dialog/upload-ubl-dialog.component";
import { DisplayedColumnsComponent } from "./displayed-columns/displayed-columns.component";
import { NotifyDialog } from "./notify/notify.component";
import { AddContactPersonDialogComponent } from "./add-contact-person-dialog/add-contact-person-dialog.component";
import { UnsavedChangesConfirmDialogComponent } from './unsaved-changes-confirm-dialog/unsaved-changes-confirm-dialog.component';
import { ConfirmBudgetExcelExportComponent } from './confirm-budget-excel-export/confirm-budget-excel-export.component';
import { ConfirmWithWarningDialogComponent } from "./confirm-with-warning/confirm-with-warning-dialog.component";
import { IncomingInvoiceLinesValidationDialogComponent } from "./handle-incoming-invoice-finance-info-dialog/incoming-invoice-lines-validation-dialog.component";
import { PaginationComponent } from './pagination/pagination.component';
import { BookingExtraConfirmationComponent } from './booking-extra-confirmation/booking-extra-confirmation.component';
import { BookingTemplatesComponent } from './booking-templates/booking-templates.component';
import { ReleasePreviewDialogComponent } from './release-preview-dialog/release-preview-dialog.component';
import { SetGoodsOwnerForInvoiceCustomerDialogComponent } from './set-goods-owner-for-invoice-customer-dialog/set-goods-owner-for-invoice-customer-dialog.component';
import { ConfirmTextValueComponent } from "./confirm-text-value/confirm-text-value.component";
import { AddEmailTemplateDialogComponent } from "./add-email-template-type-dialog/add-email-template-type-dialog.component";
import { UpdateStepsPartiesLinkingDialogComponent } from "./update-steps-parties-linking-dialog/update-steps-parties-linking-dialog.component";
import { AlertDialog } from "./alert/alert.component";
import { SelectableSuggestionDialogComponent } from "./selectable-suggestion-dialog/selectable-suggestion-dialog-component";
import { UploadDocumensDialogComponent } from "./upload-documents-dialog/upload-documents-dialog-component";
import { CreatedEntitiesListDialogComponent } from "./created-entities-list-dialog/created-entities-list-dialog-component";
import { DataConflictDialogComponent } from "./data-conflict.dialog/data-conflict.dialog.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ForwardingComponentModule
  ],
  declarations: [
    AlertDialog,
    ConfirmDialog,
    ConfirmOutgoingInvoiceLinesComponent,
    ConfirmDatePickerComponent,
    ConfirmDropDownWithDescriptionDialog,
    VgmConfirmComponent,
    ConfirmTextValueComponent,
    ConfirmWithReasonDialog,
    ConfirmPartyDropDownComponent,
    UploadUblDialog,
    DisplayedColumnsComponent,
    DataConflictDialogComponent,
    CreatedEntitiesListDialogComponent,
    SelectableSuggestionDialogComponent,
    NotifyDialog,
    AddContactPersonDialogComponent,
    UnsavedChangesConfirmDialogComponent,
    ConfirmWithWarningDialogComponent,
    ConfirmBudgetExcelExportComponent,
    IncomingInvoiceLinesValidationDialogComponent,
    PaginationComponent,
    UploadDocumensDialogComponent,
    BookingTemplatesComponent,
    BookingExtraConfirmationComponent,
    ReleasePreviewDialogComponent,
    SetGoodsOwnerForInvoiceCustomerDialogComponent,
    UpdateStepsPartiesLinkingDialogComponent,
    AddEmailTemplateDialogComponent
  ],
  exports: [
    NotifyDialog,
    ConfirmDialog,
    ConfirmOutgoingInvoiceLinesComponent,
    ConfirmDatePickerComponent,
    ConfirmPartyDropDownComponent,
    UploadUblDialog
  ]
})
export class DialogModule { }
