<div>
  <h4 class="mb-1"
      mat-dialog-title>{{ data.dialogTitle }}</h4>
  <div mat-dialog-content>
    <p>{{ data.confirmationText }}</p>
  </div>
</div>
<div align="end"
     class="option-buttons">
  <div ngbTooltip="{{ 'MESSAGES.UNSAVED_CHANGES_ERRORS' | translate }}"
       (ngModelChange)="cdRef.detectChanges()"
       [disableTooltip]="data.isSaveAllowed"
       [openDelay]="300"
       placement="bottom">
    <button (click)="saveChanges()"
            [disabled]="!data.isSaveAllowed"
            class="m-btn btn"
            [class.btn-primary]="data.isSaveAllowed"
            [class.disabled]="!data.isSaveAllowed">
      <i class="la la-arrow-up"></i>
      <span>{{ "ACTIONS.SAVE_CHANGES" | translate }}</span>
    </button>
  </div>
  <button (click)="discardChanges()"
          class="m-btn btn btn-danger">
    <i class="la la-arrow-left "></i>
    <span>{{"ACTIONS.DISCARD_CHANGES" | translate }}</span>
  </button>
  <button (click)="remainOnPage()"
          class="m-btn btn btn-secondary">
    <i class="la la-arrow-down"></i>
    <span *ngIf="data.isSaveAllowed">{{ "ACTIONS.REMAIN_ON_PAGE" | translate }}</span>
    <span *ngIf="!data.isSaveAllowed">{{ "ACTIONS.REMAIN_AND_FIX_ERRORS" | translate }}</span>
  </button>
</div>