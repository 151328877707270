<h2 *ngIf="!title"
    class="m-0 p-0 pb-4">{{'MODALS.RELEASE_NOTES' | translate}}</h2>
<h2 *ngIf="title"
    class="m-0 p-0 pb-4">{{data.title}}</h2>
<div mat-dialog-content
     class="pr-0">
  <div class="row release-content-container">
    <div class="col-12 row separator-top separator-bottom release-content pl-2">
      <div class="col-3 separator-right h-100 m-0 p-0 release-list">
        <div *ngFor="let item of releases"
             (click)="getReleasePreview(item.id)"
             [class.active-release]="item.id === activeReleaseId"
             class="col-12 row pt-3 separator-bottom m-0 p-0 release-info">
          <h4 class="col-12 bold truncate max-width-80">{{item.name}}</h4>
          <h5 class="col-12 pt-1">{{item.activeFromTimestamp | date: 'dd/MM/yyyy' || '-'}}</h5>
        </div>
      </div>
      <div [innerHtml]="previewHtml"
           class="pt-2 pl-1 pr-1 html-preview">

      </div>
    </div>
  </div>
  <div mat-dialog-actions
       align="end"
       class="mt-3">
    <button (click)="cancel()"
            class="m-btn btn btn-secondary mr-4">
      <i class="la la-remove"></i>
      <span>{{ 'ACTIONS.CLOSE' | translate }}</span>
    </button>
  </div>
