<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{ "OPERATIONAL.EDIT.VOYAGE" | translate }}</label>
  <div class="col-9">
    <input class="form-control m-input"
           [class.is-invalid]="submitted && formControl.errors"
           [formControl]="formControl"
           type="text"
           maxlength="4097">
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.maxlength"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.VOYAGE' | translate, max: 4096 }">
        VALIDATION.MAX_LENGTH
      </div>
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.VOYAGE' | translate }">
        VALIDATION.REQUIRED
      </div>
    </div>
  </div>
</div>
