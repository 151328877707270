/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BudgetDetailsPerFileSearchCondition } from '../model/budgetDetailsPerFileSearchCondition';
import { BudgetperActivityCodeFilter } from '../model/budgetperActivityCodeFilter';
import { FoundYearlyBudgetOverviewDTO } from '../model/foundYearlyBudgetOverviewDTO';
import { OperationalBudgetDetailsPerFileSearchCondition } from '../model/operationalBudgetDetailsPerFileSearchCondition';
import { OperationalFileOverviewSearchCondition } from '../model/operationalFileOverviewSearchCondition';
import { OperationalOpenBudgetPerFileSearchCondition } from '../model/operationalOpenBudgetPerFileSearchCondition';
import { OperationalSummaryPerUserFilter } from '../model/operationalSummaryPerUserFilter';
import { OutgoingInvoicesPerOperationalFileFilter } from '../model/outgoingInvoicesPerOperationalFileFilter';
import { PagedResponseFoundBudgetDetailsPerOperationalFileDTO } from '../model/pagedResponseFoundBudgetDetailsPerOperationalFileDTO';
import { PagedResponseFoundBudgetPerActivityCodeDTO } from '../model/pagedResponseFoundBudgetPerActivityCodeDTO';
import { PagedResponseFoundOpenBudgetPerOperationalFileDTO } from '../model/pagedResponseFoundOpenBudgetPerOperationalFileDTO';
import { PagedResponseFoundOperationalSummaryPerUserDTO } from '../model/pagedResponseFoundOperationalSummaryPerUserDTO';
import { PagedResponseOperationalFileOverviewDTO } from '../model/pagedResponseOperationalFileOverviewDTO';
import { PagedResponseOutgoingInvoicesPerOperationalFileDTO } from '../model/pagedResponseOutgoingInvoicesPerOperationalFileDTO';
import { PivotTableResponseReportBudgetDetailsPerOperationalFileDTO } from '../model/pivotTableResponseReportBudgetDetailsPerOperationalFileDTO';
import { ReportBudgetDetailsTotalsDTO } from '../model/reportBudgetDetailsTotalsDTO';
import { ReportOpenBudgetTotalsDTO } from '../model/reportOpenBudgetTotalsDTO';
import { Response } from '../model/response';
import { YearlyBudgetOverviewDTO } from '../model/yearlyBudgetOverviewDTO';
import { YearlyBudgetRequestByFilterDTO } from '../model/yearlyBudgetRequestByFilterDTO';
import { YearlyBudgetRequestDTO } from '../model/yearlyBudgetRequestDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class YearlyBudgetReportService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get a budget details per operational file totals
     * 
     * @param minDate 
     * @param maxDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public budgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ReportBudgetDetailsTotalsDTO>;
    public budgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ReportBudgetDetailsTotalsDTO>>;
    public budgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ReportBudgetDetailsTotalsDTO>>;
    public budgetDetailsTotals(minDate?: Date, maxDate?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (minDate !== undefined && minDate !== null) {
            queryParameters = queryParameters.set('minDate', <any>minDate.toISOString());
        }
        if (maxDate !== undefined && maxDate !== null) {
            queryParameters = queryParameters.set('maxDate', <any>maxDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ReportBudgetDetailsTotalsDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-budget-details-per-file/totals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Checks whether a report has been generated for early budget
     * 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkYearlyBudgetReportByRequestId(requestId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public checkYearlyBudgetReportByRequestId(requestId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public checkYearlyBudgetReportByRequestId(requestId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public checkYearlyBudgetReportByRequestId(requestId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling checkYearlyBudgetReportByRequestId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<boolean>(`${this.basePath}/api/reporting/yearly-budget/check-request/${encodeURIComponent(String(requestId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Check Yearly Budget Request With Valid Filters
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkYearlyBudgetRequestWithValidFilters(body?: YearlyBudgetOverviewDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<YearlyBudgetRequestByFilterDTO>;
    public checkYearlyBudgetRequestWithValidFilters(body?: YearlyBudgetOverviewDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<YearlyBudgetRequestByFilterDTO>>;
    public checkYearlyBudgetRequestWithValidFilters(body?: YearlyBudgetOverviewDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<YearlyBudgetRequestByFilterDTO>>;
    public checkYearlyBudgetRequestWithValidFilters(body?: YearlyBudgetOverviewDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<YearlyBudgetRequestByFilterDTO>(`${this.basePath}/api/reporting/yearly-budget/request/check`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a budget details per file
     * 
     * @param showFilesWithoutBudget 
     * @param minDate 
     * @param maxDate 
     * @param sortDirection 
     * @param sortProperty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetDetailsPerFile(showFilesWithoutBudget?: boolean, minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PivotTableResponseReportBudgetDetailsPerOperationalFileDTO>;
    public getBudgetDetailsPerFile(showFilesWithoutBudget?: boolean, minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PivotTableResponseReportBudgetDetailsPerOperationalFileDTO>>;
    public getBudgetDetailsPerFile(showFilesWithoutBudget?: boolean, minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PivotTableResponseReportBudgetDetailsPerOperationalFileDTO>>;
    public getBudgetDetailsPerFile(showFilesWithoutBudget?: boolean, minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showFilesWithoutBudget !== undefined && showFilesWithoutBudget !== null) {
            queryParameters = queryParameters.set('ShowFilesWithoutBudget', <any>showFilesWithoutBudget);
        }
        if (minDate !== undefined && minDate !== null) {
            queryParameters = queryParameters.set('MinDate', <any>minDate.toISOString());
        }
        if (maxDate !== undefined && maxDate !== null) {
            queryParameters = queryParameters.set('MaxDate', <any>maxDate.toISOString());
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PivotTableResponseReportBudgetDetailsPerOperationalFileDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-budget-details-per-file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a budget details per file report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetDetailsPerFileReport(body?: BudgetDetailsPerFileSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getBudgetDetailsPerFileReport(body?: BudgetDetailsPerFileSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getBudgetDetailsPerFileReport(body?: BudgetDetailsPerFileSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getBudgetDetailsPerFileReport(body?: BudgetDetailsPerFileSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/reporting/yearly-budget/operational-budget-details-per-file-report`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get budget per activity code
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetPerActivityCode(body?: BudgetperActivityCodeFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundBudgetPerActivityCodeDTO>;
    public getBudgetPerActivityCode(body?: BudgetperActivityCodeFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundBudgetPerActivityCodeDTO>>;
    public getBudgetPerActivityCode(body?: BudgetperActivityCodeFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundBudgetPerActivityCodeDTO>>;
    public getBudgetPerActivityCode(body?: BudgetperActivityCodeFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundBudgetPerActivityCodeDTO>(`${this.basePath}/api/reporting/yearly-budget/budget-per-activity-codes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get budget per activity code report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetPerActivityCodeReport(body?: BudgetperActivityCodeFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public getBudgetPerActivityCodeReport(body?: BudgetperActivityCodeFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public getBudgetPerActivityCodeReport(body?: BudgetperActivityCodeFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public getBudgetPerActivityCodeReport(body?: BudgetperActivityCodeFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/reporting/yearly-budget/budget-per-activity-codes-report`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get open budget per operational file totals
     * 
     * @param min 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOpenBudgetPerOperationalFileTotals(min?: Date, max?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ReportOpenBudgetTotalsDTO>;
    public getOpenBudgetPerOperationalFileTotals(min?: Date, max?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ReportOpenBudgetTotalsDTO>>;
    public getOpenBudgetPerOperationalFileTotals(min?: Date, max?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ReportOpenBudgetTotalsDTO>>;
    public getOpenBudgetPerOperationalFileTotals(min?: Date, max?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (min !== undefined && min !== null) {
            queryParameters = queryParameters.set('Min', <any>min.toISOString());
        }
        if (max !== undefined && max !== null) {
            queryParameters = queryParameters.set('Max', <any>max.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ReportOpenBudgetTotalsDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-open-budget-per-file/totals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file overview excel report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileOverviewReport(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public getOperationalFileOverviewReport(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public getOperationalFileOverviewReport(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public getOperationalFileOverviewReport(body?: OperationalFileOverviewSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/reporting/yearly-budget/operational-file-overview-report`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational open budget per file report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalOpenBudgetPerFileReport(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOperationalOpenBudgetPerFileReport(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOperationalOpenBudgetPerFileReport(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOperationalOpenBudgetPerFileReport(body?: OperationalOpenBudgetPerFileSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/reporting/yearly-budget/operational-open-budget-per-file`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational summary per user report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalSummaryPerUserReport(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOperationalSummaryPerUserReport(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOperationalSummaryPerUserReport(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOperationalSummaryPerUserReport(body?: OperationalSummaryPerUserFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/reporting/yearly-budget/operational-summary-per-user`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get outgoing invoices per operational file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoicesPerOperationalFile(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseOutgoingInvoicesPerOperationalFileDTO>;
    public getOutgoingInvoicesPerOperationalFile(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseOutgoingInvoicesPerOperationalFileDTO>>;
    public getOutgoingInvoicesPerOperationalFile(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseOutgoingInvoicesPerOperationalFileDTO>>;
    public getOutgoingInvoicesPerOperationalFile(body?: OutgoingInvoicesPerOperationalFileFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseOutgoingInvoicesPerOperationalFileDTO>(`${this.basePath}/api/reporting/yearly-budget/outgoing-invoices-per-operational-file/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get outgoing invoices per operational file report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoicesPerOperationalFileReport(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOutgoingInvoicesPerOperationalFileReport(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOutgoingInvoicesPerOperationalFileReport(body?: OutgoingInvoicesPerOperationalFileFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOutgoingInvoicesPerOperationalFileReport(body?: OutgoingInvoicesPerOperationalFileFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/reporting/yearly-budget/outgoing-invoices-per-operational-file-report`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get yearly budget overview
     * 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getYearlyBudgetOverviewDataByRequestId(requestId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundYearlyBudgetOverviewDTO>>;
    public getYearlyBudgetOverviewDataByRequestId(requestId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundYearlyBudgetOverviewDTO>>>;
    public getYearlyBudgetOverviewDataByRequestId(requestId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundYearlyBudgetOverviewDTO>>>;
    public getYearlyBudgetOverviewDataByRequestId(requestId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getYearlyBudgetOverviewDataByRequestId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<FoundYearlyBudgetOverviewDTO>>(`${this.basePath}/api/reporting/yearly-budget/request/${encodeURIComponent(String(requestId))}/search`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get yearly budget overview report file
     * 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getYearlyBudgetOverviewReportFile(requestId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getYearlyBudgetOverviewReportFile(requestId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getYearlyBudgetOverviewReportFile(requestId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getYearlyBudgetOverviewReportFile(requestId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getYearlyBudgetOverviewReportFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/reporting/yearly-budget/${encodeURIComponent(String(requestId))}/yearly-budget-overview-file`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get Yearly Budget Request Info
     * 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getYearlyBudgetRequestInfo(requestId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<YearlyBudgetRequestDTO>;
    public getYearlyBudgetRequestInfo(requestId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<YearlyBudgetRequestDTO>>;
    public getYearlyBudgetRequestInfo(requestId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<YearlyBudgetRequestDTO>>;
    public getYearlyBudgetRequestInfo(requestId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getYearlyBudgetRequestInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<YearlyBudgetRequestDTO>(`${this.basePath}/api/reporting/yearly-budget/request/${encodeURIComponent(String(requestId))}/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Request yearly budget report generation
     * 
     * @param year 
     * @param customerAddressId 
     * @param departmentId 
     * @param activityCodeIds 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestYearlyBudgetReportGeneration(year: number, customerAddressId?: number, departmentId?: number, activityCodeIds?: Array<number>, supplierId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public requestYearlyBudgetReportGeneration(year: number, customerAddressId?: number, departmentId?: number, activityCodeIds?: Array<number>, supplierId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public requestYearlyBudgetReportGeneration(year: number, customerAddressId?: number, departmentId?: number, activityCodeIds?: Array<number>, supplierId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public requestYearlyBudgetReportGeneration(year: number, customerAddressId?: number, departmentId?: number, activityCodeIds?: Array<number>, supplierId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling requestYearlyBudgetReportGeneration.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (customerAddressId !== undefined && customerAddressId !== null) {
            queryParameters = queryParameters.set('CustomerAddressId', <any>customerAddressId);
        }
        if (departmentId !== undefined && departmentId !== null) {
            queryParameters = queryParameters.set('DepartmentId', <any>departmentId);
        }
        if (activityCodeIds) {
            activityCodeIds.forEach((element) => {
                queryParameters = queryParameters.append('ActivityCodeIds', <any>element);
            })
        }
        if (supplierId !== undefined && supplierId !== null) {
            queryParameters = queryParameters.set('SupplierId', <any>supplierId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/reporting/yearly-budget/request`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search a budget details per operational file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBudgetDetailsPerOperationalFile(body?: OperationalBudgetDetailsPerFileSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundBudgetDetailsPerOperationalFileDTO>;
    public searchBudgetDetailsPerOperationalFile(body?: OperationalBudgetDetailsPerFileSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundBudgetDetailsPerOperationalFileDTO>>;
    public searchBudgetDetailsPerOperationalFile(body?: OperationalBudgetDetailsPerFileSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundBudgetDetailsPerOperationalFileDTO>>;
    public searchBudgetDetailsPerOperationalFile(body?: OperationalBudgetDetailsPerFileSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundBudgetDetailsPerOperationalFileDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-budget-details-per-file/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search an open budget per operational file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOpenBudgetPerOperationalFile(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOpenBudgetPerOperationalFileDTO>;
    public searchOpenBudgetPerOperationalFile(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOpenBudgetPerOperationalFileDTO>>;
    public searchOpenBudgetPerOperationalFile(body?: OperationalOpenBudgetPerFileSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOpenBudgetPerOperationalFileDTO>>;
    public searchOpenBudgetPerOperationalFile(body?: OperationalOpenBudgetPerFileSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundOpenBudgetPerOperationalFileDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-open-budget-per-file/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file overview report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOperationalFileOverview(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseOperationalFileOverviewDTO>;
    public searchOperationalFileOverview(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseOperationalFileOverviewDTO>>;
    public searchOperationalFileOverview(body?: OperationalFileOverviewSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseOperationalFileOverviewDTO>>;
    public searchOperationalFileOverview(body?: OperationalFileOverviewSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseOperationalFileOverviewDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-overview/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search operational summary per user
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOperationalSummaryPerUser(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOperationalSummaryPerUserDTO>;
    public searchOperationalSummaryPerUser(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOperationalSummaryPerUserDTO>>;
    public searchOperationalSummaryPerUser(body?: OperationalSummaryPerUserFilter, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOperationalSummaryPerUserDTO>>;
    public searchOperationalSummaryPerUser(body?: OperationalSummaryPerUserFilter, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundOperationalSummaryPerUserDTO>(`${this.basePath}/api/reporting/yearly-budget/operational-summary-per-user/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
