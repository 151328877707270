export const notifications_list_locale = {
  TITLE: "Notifications",
  SHOW_ARCHIVED: "Show archived",
  ARCHIVED_ON: "Archived on {{date}}",
  BY_USER: "by {{user}}",
  EXPAND: "Expand",
  COLLAPSE: "Collapse",
  MARK_AS_READ: "Mark as read",
  MESSAGES:
  {
    READ_SUCCESSFULLY: "The notification was marked as read.",
    NO_MORE_ACTIVE_NOTIFICATIONS: "There are no more active notifications."
  },
  MESSAGE: "Message",
  MESSAGE_DATE: "Message date",
};
