export const enums_locale = {

  OPERATIONAL_FILE_TYPE: {
    IMPORT: "Import", // 0
    EXPORT: "Export", // 1
    CROSSTRADE: "CrossTrade", // 2
    WHS: "Warehousing (WHS)", // 3
    WIMPORT: "Import + WHS", // 0
    WEXPORT: "Export + WHS", // 1
    WCROSSTRADE: "CrossTrade + WHS"// 2
  },
  TRANSPORT_TYPE: {
    SEA: "Sea", // 0
    AIR: "Air", // 1
    RAIL: "Rail", // 2
    ROAD: "Road"// 3
  },
  SHIPMENT_TYPE: {
    FCL: "FCL", // 0
    LCL: "LCL", // 1
    BREAKBULK: "Breakbulk", // 2
    RORO: "RoRo", // 3
    FTL: "FTL", // 4
    LTL: "LTL", // 5
    HAULAGE: "Haulage"// 6
  },
  SUPPLIER_COMUNICATION_TYPE: {
    INTTRA: "INTTRA", // 0
    EDI: "EDI", // 1
  },
};

