<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{ "OPERATIONAL.EDIT.CUSTOMERREFERENCE" | translate }}</label>
  <div class="col-9 d-flex">
    <input class="form-control m-input"
           [class.is-invalid]="submitted && formControl.errors"
           [formControl]="formControl"
           type="text"
           maxlength="513">
    <div>
      <i *ngIf="showWarning"
         ngbTooltip="{{ 'OPERATIONAL.EDIT.MESSAGES.CUSTOMER_REFERENCE_INTTRA_WARNING' | translate }}"
         placement="bottom"
         class="fas fa-exclamation-triangle warning-style"></i>
    </div>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.maxlength"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.CUSTOMERREFERENCE' | translate, max: 512 }">
        VALIDATION.MAX_LENGTH
      </div>
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: 'OPERATIONAL.EDIT.CUSTOMERREFERENCE' | translate }">
        VALIDATION.REQUIRED
      </div>
    </div>
  </div>
</div>
