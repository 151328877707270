<!-- TODO: this component should not be tightly coupled with bill of lading, rather textarea should be the type, validators and rows etc.
   should receive their settings from the backend... -->

<div class="form-group row ">
  <label class="col-form-label col-3 py-0">{{ "OPERATIONAL.GENERAL.BILLOFLADINGNR" | translate }}</label>
  <div class="col-9">
    <textarea class="form-control m-input"
              [class.is-invalid]="submitted && formControl.errors"
              [formControl]="formControl"
              trimStart
              cols="1"
              maxlength="1024"
              rows="5"></textarea>
    <char-counter [control]="formControl"
                  [maxCharCount]="1024"></char-counter>
    <div *ngIf="submitted && formControl.errors"
         class="invalid-feedback">
      <div *ngIf="formControl.errors.maxlength"
           translate
           [translateParams]="{ name: 'OPERATIONAL.GENERAL.BILLOFLADINGNR' | translate, max: 1024 }">
        VALIDATION.MAX_LENGTH
      </div>
      <div *ngIf="formControl.errors.required"
           translate
           [translateParams]="{ name: 'OPERATIONAL.GENERAL.BILLOFLADINGNR' | translate }">
        VALIDATION.REQUIRED
      </div>
      <div *ngIf="formControl.errors.max30charsPerLineNoSpaces">
        {{'OPERATIONAL.GENERAL.VALIDATORS.BILL_OF_LADING_MAX_CHARS' | translate  : '{ max_chars: 30 }' }}
      </div>
    </div>
  </div>
</div>
