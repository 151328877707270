<div class="text-to-label-overlay">

  <ng-container>
    <div class="ng-value"
         [class.disabled]="disabled"
         *ngFor="let line of getLines()">
      <span class="ng-value-label"> {{ line.text }} </span>
      <span class="ng-value-border">|</span>
      <span class="ng-value-icon right"
            [class.disabled]="disabled"
            aria-hidden="true"
            (click)="clear(line)">×</span>
    </div>

    <div *ngIf="lines.length > 3"
         class="last-item">
      <div *ngIf="!showAll"
           class="ng-value remaining-values">
        <span class="ng-value-label ">{{lines?.length - 3}} {{ 'MORE_ELLIPSIS' | translate }}</span>
      </div>

      <div class="ng-value"
           [class.disabled]="disabled"
           *ngIf="showAll">
        <span class="ng-value-label"> {{ getLastLine().text }} </span>
        <span class="ng-value-border">|</span>
        <span class="ng-value-icon right"
              [class.disabled]="disabled"
              aria-hidden="true"
              (click)="clear(getLastLine())">×</span>
      </div>

      <div (click)="toggleShowAll()">
        <i *ngIf="showAll"
           class="fas fa-angle-double-up"></i>
        <i *ngIf="!showAll"
           class="fas fa-angle-double-down"></i>
      </div>
    </div>
  </ng-container>

</div>
