export const dashboards_locale = {
  ADD_DASHBOARD: "Add new dashboard",
  EDIT_DASHBOARD: "Edit dashboard",
  REFRESH: "refresh",
  ADD_WIDGET: "add widget",
  SAVE_DASHBOARD: "save dashboard",
  DELETE_DASHBOARD: "delete dashboard",
  CANCEL: "cancel",
  AVAILABLE_WIDGETS: "Available widgets",
  NO_DASHBOARD_FOUND: "No dashboards could be found.",
  SET_DASHBOARDS_PAGE_AS_DEFAULT: "Set Dashboards page as default",
  MODALS: {
    SET_DASHBOARD_NAME_TITLE: 'Dashboard name',
    SET_DASHBOARD_NAME: 'Choose a dashboard name',
    DELETE_DASHBOARD_TITLE: "Delete dashboard",
    DELETE_DASHBOARD: "Are you sure you want to delete this dashboard?",
    CANCEL_CONFIRMATION_TITLE: "Cancel dashboard changes",
    CANCEL_CONFIRMATION: "Are you sure you want to cancel unsaved changes?",
  },
  WIDGETS: {
    OPERATIONAL_FILES: {
      FILE_NUMBER: "Operational file nr.",
      FILE_TYPE: "File type",
      STEPS: "Steps",
      STEPS_TEXT: "Completed steps / Total steps",
    },
    OPERATIONAL_FILE_STEPS: {
      FILE_NUMBER: "Operational file nr.",
      DESCRIPTION: "Description",
      EXPIRATION_DATE: "Expiration date",
      STEP_ACTIVE: "Active",
      STEP_EXPIRED: "Expired",
      STEP_EXPIRE_TOMORROW: "Expire tomorrow",
    },
  }
};
