import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class FormlySuggestionsSynchronizationHelper {
  //ETA
  private dateSuggestionSubject: Subject<FormlyDateSuggestion> = new Subject();

  public setDateSuggestion(suggestion: FormlyDateSuggestion) {
    this.dateSuggestionSubject.next(suggestion);
  }

  public getDateSuggestionObservable$(): Observable<FormlyDateSuggestion> {
    return this.dateSuggestionSubject.asObservable();
  }
}
export class FormlyDateSuggestion {
  public id: string;
  public suggestion: Date;
}
