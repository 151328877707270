import { of_tariffs_import_locale } from "./of-tariffs.en";

export const masterdata_locale = {
  ACTIVE: "Active",
  BLOCKED: "Blocked",
  TO_BE_REVIEWED: "To be reviewed",
  CONFIRM_BLOCK: "Please confirm block for {{numberToBlock}} item(s)",
  CONFIRM_UNBLOCK: "Please confirm unblock for {{numberToUnblock}} item(s)",
  BLOCKED_SUCCESS: "The selected items were successfully blocked",
  UNBLOCKED_SUCCESS: "The selected items were successfully unblocked",
  CONFIRM_OPERATION: "Confirm operation",
  ENTITY_USES_MESSAGE: "This {{entityName}} is actively being used in an operational file or a quote file or an invoice. When making significant changes, it’s advised to block this {{entityName}} and create a new one instead. This protects the integrity of older data. Are you sure you want to continue?",
  PLACES: {
    ADD_NEW_PLACE: "Add a new place",
    PLACE_DETAILS: "Place details",
    LIST_TITLE: "Places",
  },
  IMPORT_TARIFFS: of_tariffs_import_locale,
  CONTACTPERSONTYPE: {
    ADD_NEW: "Add a new contact person type",
    UPDATE: "Contact person type details",
    TITLE: "Contact person types",
    TYPE_LABEL: "Contact person type"
  },
  COUNTRY: {
    ADD_NEW_COUNTRY: "Add a new country",
    COUNTRY_DETAILS: "Country details",
    IS_PART_OF_EU: "Country is part of the European Union",
    LIST_TITLE: "Countries"
  },
  COMPANIES: {
    CLIENT_PORTAL: "Client portal",
    NEW_CLIENT_PORTAL_INVITATION: "Invite new user",
    GENERAL: "General",
    CONTACTS: "Contacts",
    ADDRESSES: "Addresses",
    FINANCIAL_DATA: "Financial data",
    INTEGRATIONS: "Integrations",
    TARIFFS: "Tariffs",
    HAS_INTTRA_INTERFACE: "Inttra interface?",
    NON_STANDART_VAT_NUMBER: "Non-standard VAT number. Are you sure this is correct?",
    NEW_ACCOUNTING_NUMBER: "New accounting number",
    BLOCK_SINGLE_COMPANY_CONFIRM: "The selected company has been blocked!",
    BLOCK_MULTIPLE_COMPANY_CONFIRM: "The selected companies have been blocked!",
    CONFIRM_BLOCK: "Are you sure you want to block {{numberItems}} company/companies?",
    IS_BILLABLE: "Invoiceable",
    IS_VAT_LIABLE: "Is VAT liable",
    INTEGRATION_TYPE: "Integration type",
    INTEGRATION: "Integration",
    OWNER_OF_GOODS_THEY_EXPORT: "Owner of goods they export",
    NO_DUPLICATE_INTEGRATIONS: "No duplicate integrations allowed. Please remove any duplicates and try saving again.",
    POPOVER: {
      ATTENTION: "Attention",
      VAT_ATTENTION: "For VAT purposes we need to know the owner of goods that are exported out of the EU. This setting can be overridden on individual operational files and even individual invoice lines."
    }
  },
  LOCATIONS: {
    LIST_TITLE: "Locations",
    ADD_NEW: "Add a new location",
    DETAILS: "Location details",
    CONFIRM_BLOCK: "Are you sure you want to block {{numberItems}} location(s)?",
    CONFIRM_UNBLOCK: "Are you sure you want to unblock {{ numberToUnblock }} location(s)?",
    BLOCK_SINGLE_COMPANY_CONFIRM: "The selected location has been blocked!",
    BLOCK_MULTIPLE_COMPANY_CONFIRM: "The selected locations have been blocked!",
    UNBLOCK_SINGLE_COMPANY_CONFIRM: "The selected location has been unblocked!",
    UNBLOCK_MULTIPLE_COMPANY_CONFIRM: "The selected locations have been unblocked!",
  },
  SUPPLIERS: {
    ADD_NEW_SUPPLIER: "Add a new supplier",
    SUPPLIER_DETAILS: "Supplier details",
    LIST_TITLE: "Suppliers",
    SUPPLIER_CREATED: "'{{ name }}' has been created successfully.",
    SELECT_ADDRESSES: "Select address(es)",
    BLOCK_SINGLE_CONFIRM: "The selected supplier has been blocked!",
    BLOCK_MULTIPLE_CONFIRM: "The selected suppliers have been blocked!",
    UNBLOCK_SINGLE_CONFIRM: "The selected supplier has been unblocked!",
    UNBLOCK_MULTIPLE_CONFIRM: "The selected suppliers have been unblocked!",
    MESSAGES: {
      CONFIRM_BLOCK: "Are you sure you want to block {{ numberToBlock }} supplier(s)?",
      CONFIRM_UNBLOCK: "Are you sure you want to unblock {{ numberToUnblock }} supplier(s)?"
    },
    VALIDATION: {
      CARRIER_IDENTIFICATION_REQUIRED: "Carrier Identification is required when communication type specified.",
      SCAC_CODE_REQUIRED: "SCAC code is required when enabling 'Inttra interface'.",
    }
  },
  CURRENCIES: {
    ADD_NEW_CURRENCY: "Add a new currency",
    CURRENCY_DETAILS: "Currency details",
    LIST_TITLE: "Currencies"
  },
  ADDRESSES: {
    ADD_ADDRESS_TITLE: "Add a new address",
    ADDRESS_DETAILS_TITLE: "Address details"
  },
  LANGUAGES: {
    ADD_NEW_LANGUAGE: "Add a new language",
    LANGUAGE_DETAILS: "Language details",
    LIST_TITLE: "Languages"
  },
  PORTS: {
    ADD_PORT: "Add a new port",
    PORT_CODE_REQUIRED: "Either an IATA or UNLO code must be provided, but not both.",
    DETAILS: "Port details",
    LIST_TITLE: "Ports",
    PLACEHOLDERS: {
      NAME: "Port name"
    }
  },
  VESSELS: {
    ADD_NEW_VESSEL: "Add a new vessel",
    VESSEL_DETAILS: "Vessel details",
    LIST_TITLE: "Vessels",
    ADD_VESSEL: "Add vessel",
    CONFIRM_NEW_VESSEL: "Are you sure you want to add vessel '{{ name }}'?",
    CAN_NOT_BE_EMPTY: "The vessel name can't be empty."
  },
  TERMINALS: {
    ADD_TITLE: "Add a new terminal",
    DETAILS_TITLE: "Terminal details",
    LIST_TITLE: "Terminals",
  },
  STEPS: {
    ADD_TITLE: "Add a new step",
    DETAILS_TITLE: "Step details",
    LIST_TITLE: "Steps",
    PLACEHOLDERS: {
      TEMPLATE: "Template",
      DURATION: "Duration",
      DAYS: "Days",
      HOURS: "Hours",
      BASED_ON: "Based on",
      PARTY: "Party",

      OPERATIONAL_FILE_DATE: "Operational file date",
      OTHER_STEP: "Other step",
      BEFORE_ETD: "Before ETD",
      AFTER_ETD: "After ETD",
      BEFORE_ETA: "Before ETA",
      AFTER_ETA: "After ETA",
      AFTER_ACTUAL_DATE_OF_LOADING: "After actual date of loading",
      AFTER_BERTHDATE: "After berth date",
      AFTER_ACTUAL_TIME_OF_DEPARTURE: "After actual time of departure",
      AFTER_ACTUAL_TIME_OF_ARRIVAL: "After actual time of arrival",
      BEFORE_CLOSING_VESSEL: "Before closing vessel",
      BEFORE_CLOSING_DOCUMENT: "Before closing document",
      BEFORE_CLOSING_AMS: "Before closing AMS",
      BEFORE_CLOSING_VGM: "Before closing VGM",
      BEFORE_CLOSING_IMO: "Before closing IMO",
      BEFORE_CLOSING_REEFER: "Before closing reefer",
      THIS_STEP_HAS_A_PLACEHOLDER: "This step has a placeholder(s). Please select a party to assign to this placeholder.",

      START_WHEN: "Start when",
      OTHER_STEP_IS_COMPLETED: "Other step is completed",
      OTHER_STEPS_DURATION_EXPIRES: "Other step's duration expires",
      HAS_DEADLINE: "Has deadline"
    },
    MIN_DAYS: "The days cannot be less than 1.",
    MAX_DAYS: "The days cannot be more than 3650.",
    MIN_HOURS: "The hours cannot be less than 1.",
    MAX_HOURS: "The hours cannot be more than 23.",
    DAYS_OR_HOURS_REQUIRED: "Days or hours are required.",
    CONFIGURE_STEP_TEMPLATES: "Configure steps",
    AUTOCOMPLETE_STEP: {
      AUTOCOMPLETE: "Autocomplete",
      BUDGET_CLOSED: "the operational file budget is closed and fully invoiced",
      AT_LEAST_ONE_INVOICE_FINALIZED: "at least one outgoing invoice is finalized",
      FULLY_INVOICED: "all revenue lines have been invoiced and finalized",
      BOOKING_SENT: "an INTTRA booking has been sent",
      BOOKING_ACCEPTED: "a booking was accepted by INTTRA",
      SHIPPING_INSTRUCTIONS_SENT: "shipping instructions were sent to INTTRA",
      SHIPPING_INSTRUCTIONS_ACCEPTED: "shipping instructions were accepted by INTTRA",
      VGM_COMPLETED: "all containers have received a VGM certificate",
      AUTOCOMPLETE_WHEN: "Autocomplete step when...",
      AUTOCOMPLETE_STEP: "Autocomplete step"
    },
    NOTIFY_CUSTOMER: {
      NOTIFY: "Notify customer upon completion",
      NOTIFY_CUSTOMER: "Notify customer",
      TEXT_NOTIFY: "Notification text",
      DATE_NOTIFY: "Date notify",
      NONE: "None",
      CUSTOMER: "Customer",
      INVOICE_CUSTOMER: "Invoice customer",
      BOTH: "Both"
    }
  },
  DEPOTS: {
    ADD_TITLE: "Add a new depot",
    DETAILS_TITLE: "Depot details",
    LIST_TITLE: "Depots",
    CODE: "Code",
    DESCRIPTION: "Description",
    PORT: "Port",
    ADDRESS: "Address"
  },
  HSCODES: {
    ADD_NEW_HSCODE: "Add a new HS code",
    HSCODE_DETAILS: "HS code details",
    LIST_TITLE: "HS codes",
    IMPORT_FROM_FILE: "Import HS codes from file",
    PROPOSE_UPLOAD: "Please upload an excel file of HS codes to update the existing data.",
    LEAVE_USER_EDITS: "Leave existing user edits",
    LEAVE_USER_EDITS_TOOLTIP: "Only update HS codes that were never modified by a user",
    OVERWRITE_BUT_LEAVE_USER_EDITS: "Overwrite user edits but keep custom user codes",
    OVERWRITE_BUT_LEAVE_USER_EDITS_TOOLTIP: "Update all HS codes, but do not delete user-added HS codes that are not found in the excel file",
    COMPLETELY_REPLACE: "Completely replace existing table",
    COMPLETELY_REPLACE_TOOLTIP: "Update all HS codes, delete user-added codes that were not found in the excel file",
    ERROR_STATE_MESSAGE: "One or more codes are no longer present in the file, but are still used in active operational files. After importing these codes will be moved to the 'Blocked' state. Do you want to continue?",
    DOWNLOAD_HERE: "You can download the file here:",
    CODE: "Code",
    DESCRIPTION: "Description"
  },
  STATUS: "Status",
  STATUSES: {
    INVITED: "Invited",
    ACTIVE: "Active",
    DEACTIVATED: "Deactivated",
  },
  UNITS_OF_MEASUREMENT: {
    ADD_NEW: "Add a new unit of measurement",
    DETAILS: "Unit of measurement details",
    LIST_TITLE: "Units of measurement"
  },
  SEALNUMBERTYPES: {
    ADD_NEW_SEALNUMBERTYPE: "Add a new seal number type",
    SEALNUMBERTYPE_DETAILS: "Seal number type details",
    LIST_TITLE: "Seal number types"
  },
  INCOTERMS: {
    ADD_NEW_INCOTERM: "Add a new incoterm",
    INCOTERM_DETAILS: "Incoterm details",
    LIST_TITLE: "Incoterms"
  },
  INVOICE_COMMENTS: {
    ADD_NEW_INVOICE_COMMENT: "Add invoice comment",
    INVOICE_COMMENT_DETAILS: "Invoice comment details",
    LIST_TITLE: "Invoice comments"
  },
  CONTACTPERSONS: {
    ADD_NEW_CONTACTPERSON: "Add a new contact person",
    CONTACTPERSON_DETAILS: "Contact person details",
    VALIDATION: {
      AT_LEAST_ONE_ADDRESS: "Please select at least one address"
    }
  },
  PARTY: {
    VALIDATION: {
      REQUIRED: "Party is required",
    }
  },
  CONTAINERISOCODES: {
    ADD_NEW_CONTAINERISOCODE: "Add a new container ISO code",
    CONTAINERISOCODE_DETAILS: "Container ISO code details",
    LIST_TITLE: "Container ISO codes"
  },
  ACTIVITYCODES: {
    ADD_NEW_ACTIVITYCODE: "Add a new activity code",
    ACTIVITYCODE_DETAILS: "Activity code details",
    LIST_TITLE: "Activity codes",
    TRANSLATIONS_ON_DOCUMENTS: "Translations on documents"
  },
  PARTYTYPES: {
    DETAILS: "Party type details",
    ADD_NEW_PARTYTYPE: "Add a new party type",
    LIST_TITLE: "Party types",
    SELECTABLE_TYPES_TITLE: "Selectable types:",
    SELECTABLE_CUSTOMERS: "Customers",
    SELECTABLE_SUPPLIERS: "Suppliers",
    SELECTABLE_LOCATIONS: "Locations",
    SELECTABLE_TERMINALS: "Terminals",
    SELECTABLE_DEPOTS: "Depots"
  },
  TARIFFS: {
    ACTIVITY_CODE: "Activity code",
    UOM: "Unit of measurement",
    COST_PRICE: "Cost price",
    REVENUE_PRICE: "Revenue price",
    MARGIN_PERCENTAGE: "Margin percentage",
    PORT_OF_LOADING: "Port of loading",
    PORT_OF_DISCHARGE: "Port of discharge",
    SHIPMENT_TYPE: "Shipment type",
    TRANSPORT_TYPE: "Transport type",
    FILE_TYPE: "File type",
    PLACE_OF_RECEIPT: "Place of receipt",
    PLACE_OF_DESTINATION: "Place of destination",
    ACTIONS: "Actions",
    VALIDATION: {
      REVENUEPRICE_OR_MARGINPERCENTAGE_REQUIRED: "Revenue price or margin percentage is required."
    }
  },
  PACKAGETYPES: {
    DETAILS: "Package type details",
    ADD_NEW_PACKAGETYPE: "Add a new package type",
    LIST_TITLE: "Package types"
  },
  IMOCLASSIFICATIONS: {
    DETAILS: "IMO classification details",
    ADD_NEW_IMOCLASSIFICATION: "Add a new IMO classification",
    IMOCLASSIFICATION_DETAILS: "Update IMO classification",
    UN_CODE: "UN code",
    CLASS: "Class",
    PACKAGE_GROUP: "Packing group code",
    FLASHPOINT: "Flashpoint",
    FLASHPOINT_FROM: "Flashpoint from",
    FLASHPOINT_TO: "Flashpoint to",
    EMS_CODE: "EMS code",
    MFAG_CODE: "MFAG code",
    PROPER_SHIPPING_NAME: "Proper shipping name",
    TECHNICAL_NAME: "Technical name",
    LIMITED_QUANTITY: "Limited quantity",
    INNER_QUANTITY: "Inner quantity",
    EXPECTED_QUANTITY: "Expected quantity",
    EXCEPTED_QUANTITY: "Excepted quantity",
    IS_MARINE_POLLUTANT: "Marine pollutant",
    ADR: "ADR",
    RID: "RID",
    LIST_TITLE: "IMO classifications",
    PLACEHOLDERS: {
      IMOCLASSIFICATION: "IMO classification"
    }
  },
  CUSTOMERS: {
    ADD_NEW_CUSTOMER: "Add a new customer",
    CUSTOMER_DETAILS: "Customer details",
    LIST_TITLE: "Customers",
    FINANCIAL_DATA: "Financial data",
    CUSTOMER_CREATED: "'{{ name }}' has been created successfully.",
    BLOCK_SINGLE_CONFIRM: "The selected customer has been blocked!",
    BLOCK_MULTIPLE_CONFIRM: "The selected customers have been blocked!",
    UNBLOCK_SINGLE_CONFIRM: "The selected customer has been unblocked!",
    UNBLOCK_MULTIPLE_CONFIRM: "The selected customers have been unblocked!",
    MESSAGES: {
      CONFIRM_BLOCK: "Are you sure you want to block {{ numberToBlock }} customer(s)?",
      CONFIRM_UNBLOCK: "Are you sure you want to unblock {{ numberToUnblock }} customer(s)?"
    },
    VALIDATION: {
      EORI_INVALID: "The EORI number must start with two capital letters followed by maximum 15 alphanumeric characters."
    }
  },
  FINANCIAL_DATA: {
    CREDIT_LIMIT: "Credit limit",
    DELIVERY_METHOD: "Delivery method",
    DELIVERY_MAIL: "Delivery mail",
    PAYMENT_TERM: "Payment term"
  },
  EMAIL_TEMPLATE_TYPE: {
    TEMPLATE_NAME: "Template name",
    CATEGORY_NAME: "Category name",
    ADD_NEW: "Add a new email template type",
    TEMPLATE_TYPE: "Template type",
    CATEGORIES: {
      NEW_INVOICE: "New invoice",
      INVOICE_REMINDER: "Invoice reminder",
      SEND_QUOTE: "Send quote",
      QUOTES_GENERAL: "Quotes general",
      OPERATIONAL_FILE_GENERAL: "Operational file general",
    }
  },
  EMAIL_TEMPLATE: {
    SAVE_BEFORE_CONTINUE: "You have unsaved changes. Please save your changes before changing the language."
  },
  STEP_TEMPLATES: {
    LIST_TITLE: "Step templates",
    ADD_NEW: "Add a new step template",
    DETAILS: "Step template details",
    IS_DEFAULT_CUSTOMS: "Default customs",
    IS_DEFAULT_TRANSPORTER: "Default transporter",
    IS_DEFAULT_CARRIER: "Default carrier",
    VALIDATION: {
      REQUIRED: "A step description is required."
    }
  },
  STANDARD_TEXTS: {
    LIST_TITLE: "Standard texts",
    ADD_NEW: "Add a new standard text",
    DETAILS: "Standard text details"
  },
  OCR_SETTINGS: {
    KEY: "Key",
    USAGE_CATEGORY: "Usage category",
    LIST_TITLE: "OCR settings",
    TYPE: "Type",
    VALUE: "Value",
    ADD_NEW_OCR_SETTING: "New OCR setting",
    OCR_SETTING_DETAIL: "OCR setting detail",

  }
};
