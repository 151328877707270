export const of_costs_locale = {
  RESULT: "Result",
  ACTUAL_RESULT: "Actual result",
  COSTS: "Costs",
  ADD_NEW_COST: "Add new cost",
  COST_DETAILS: "Cost details",
  ACTIVITY_CODE: "Activity code",
  PARTY: "Party",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange rate",
  WE_FOUND_OTHER_SUPPLIERS_WITH_SAME_TARIFF: "We have found other suppliers having tariffs that can be applied for this selection, do you want to continue?",
  NO_OTHER_SUPPLIERS_WITH_SAME_TARIFF_WERE_FOUND: "No other suppliers have tariffs that can be applied for this selection.",
  COMPANIES_SUGGESTIONS: "Existing companies with suitable tariffs",
  CURRENTLY_SELECTED_COMPANY: "Currently selected company",
  REVENUE_PRICE: "Revenue price",
  COST_PRICE: "Cost price",
  NO_TARIFF: "no tarrif",
  REMARKS: "Remarks",
  QUANTITY: "Quantity",
  PRICE: "Price",
  PRICE_CONVERTED: "Price",
  VAT: "VAT",
  TOTAL: "Total",
  TOTAL_CONVERTED: "Total",
  VAT_DETAILS: "VAT details",
  BOOKED: "Booked",
  ISBOOKED_DETAIL: "This cost is booked and cannot be changed.",
  COPY_TO_REVENUES: "Copy to revenues",
  NOT_BOOKED: "New",
  UNIT_OF_MEASUREMENT: "Unit of measurement",
  INVOICE_NUMBER: "Invoice nr",
  TAX_PERCENTAGE: "Tax percentage",
  TAX_SYSTEM: "Tax system",
  ISSUE_DATE: "Issue date",
  INVOICE_STATUS: "Invoice status",
  INVOICE_SUMMARY: "Invoice summary",
  ACCOUNTING_NUMBER: "Accounting nr",
  DRAFT_NUMBER: "Draft nr",
  TOTAL_INCL_VAT: "Total (incl. VAT)",
  ACCOUNTING_OR_DRAFT_NUMBER: "Accounting / Draft nr.",
  MODAL: {
    COSTS_CHANGED: "Cost lines have been changed in the meantime and need to be reloaded",
    TITLE_COSTS_CHANGED: "Cost lines have been changed",
    RELOAD_COSTS: "Reload costs"
  }
};
