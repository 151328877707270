<!-- BEGIN: Topbar -->
<!-- <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav"> -->
<div id="m_header_topbar"
     class="m-topbar m-stack m-stack--ver m-stack--general">
  <div class="m-stack__item m-topbar__nav-wrapper">
    <ul class="m-topbar__nav m-nav m-nav--inline">
      <m-notification *ngIf="hasNotificationsRole"></m-notification>
      <release-preview></release-preview>
      <m-user-profile></m-user-profile>
    </ul>
  </div>
</div>
<!-- </div> -->
<!-- END: Topbar -->
